import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, SafeAreaView, AsyncStorage } from "react-native";
import { Button } from "../../../../src/components/Button";
import { AuthenticatedUserContext } from "../../../reducers/auth/AuthStateContext";

import { useTheme } from "@react-navigation/native";
import BackgroundContainer from "../../../components/BackgroundContainer";
import Firebase from "../../../../config/firebase";
//import AuthContext from '../../../components/AuthDispatchContext';
//mport AuthState from '../../../components/AuthStateContext';
import commonStyles from "../../../../styles/common.style";
import Loading from "../../../components/Loading";
import { CustomTheme } from "../../../../styles/theme.style";
// import component

const auth = Firebase.auth();

export default function App() {
  const { user, setUser } = React.useContext(AuthenticatedUserContext);
  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  const [isLoading, setIsLoading] = useState(true);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const username = user.email;
  const id = user.uid;

  if (isLoading === true) {
    // return loading screen, if data is loading
    return <Loading />;
  }

  return (
    <BackgroundContainer>
      <View style={styles.container}>
        <SafeAreaView style={styles.settingsContainer}>
          <ScrollView style={styles.container}>
            <Text style={[commonStyles.text, appColors.text, styles.scrollView]}>
              {"Username:\t"}
              {username}
            </Text>
            <Text style={[commonStyles.text, appColors.text, styles.scrollView]}>
              {"Id: \t \t \t"}
              {id}
            </Text>
          </ScrollView>
        </SafeAreaView>
        <View style={styles.buttonContainer}>
          <Button
            title="Sign Out"
            onPress={() => handleSignOut()}
            //containerStyle={[commonStyles.btnContainer]}
            //buttonStyle={[commonStyles.btn, appColors.button, styles.btnContainer]}
          />
        </View>
      </View>
    </BackgroundContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
  },
  settingsContainer: {
    flex: 5,
    marginTop: "2%",
  },
  scrollView: {
    flex: 1,
    // padding: '4%',
    alignSelf: "flex-start",
  },
  buttonContainer: {
    alignSelf: "center",
    width: "90%",
    flex: 1,
    maxWidth: 500,
    marginHorizontal: "10%",
  },
  btnContainer: {
    shadowOpacity: 0.75,
    shadowRadius: 3,
    shadowOffset: {
      width: 1,
      height: 2,
    },
    elevation: 3,
    marginHorizontal: 5,
  },
});
