import React from "react";
import { useTheme } from "@react-navigation/native";
import {
  Pressable,
  StyleSheet,
  Text,
  GestureResponderEvent,
} from "react-native";

import commonStyles from "../../styles/common.style";
import { CustomTheme } from "../../styles/theme.style";

interface IButton {
  onPress: (event: GestureResponderEvent) => void;
  title: string;
  style?: Record<string, unknown>;
  textStyle?: Record<string, unknown>;
  innerRef?: any;
}

export const Button = (props: IButton): JSX.Element => {
  const {
    title = "Enter",
    style = {},
    textStyle = {},
    onPress,
    innerRef = undefined,
  } = props;
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  return (
    <Pressable
      ref={innerRef}
      onPress={onPress}
      style={({ pressed }) => [
        commonStyles.btn,
        styles.button,
        pressed ? appColors.button.pressed : appColors.button,
        style,
      ]}
    >
      <Text style={[commonStyles.text, appColors.text, styles.text, textStyle]}>
        {title}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    display: "flex",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  text: {
    padding: 3,
  },
});
