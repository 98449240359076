import React from "react";
import { StyleSheet, View, Button, Text, Alert, Platform } from "react-native";
import i18n from "i18n-js";
import Slider from "@react-native-community/slider";
import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import { TableState } from "../../../../types/GlobalTypes";
import { TableDispatchContext, TableStateContext } from "../../../reducers/table/Context";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import Loading from "../../../components/Loading";

interface IBrightnessProps {
  tableState: TableState.In_Game | TableState.Idle;
  heading: string;
}

export default function Brightness({ tableState, heading }: IBrightnessProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);

  const brightness = tableConfig.configuration_per_state[tableState].brightness;

  const [loading, setLoading] = React.useState(false);

  if (loading) {
    return (
      <View>
        <Loading></Loading>
      </View>
    );
  }

  const dispatchBrightness = (brightness: number) => {
    const action: Action =
      tableState === TableState.Idle
        ? {
            type: "SET_BRIGHTNESS_IDLE",
            brightness: brightness ? brightness : 0,
          }
        : {
            type: "SET_BRIGHTNESS_IN_GAME",
            brightness: brightness ? brightness : 0,
          };

    dispatch(action);
  };

  return (
    <View style={styles.container}>
      <Text style={[appColors.text, commonStyles.textLeft, styles.marginLeft]}>{heading}</Text>
      <View style={styles.setMaximumBrightnessContainer}>
        <View style={styles.sliderContainer}>
          <View>
            <Text style={appColors.text}>{brightness}</Text>
          </View>

          <Slider
            style={{ height: 40, minWidth: "90%" }}
            value={brightness ? brightness / 100 : 0}
            minimumValue={0}
            maximumValue={1}
            minimumTrackTintColor="#FFFFFF"
            maximumTrackTintColor="#000000"
            onValueChange={(value) => dispatchBrightness(Math.round(value * 100))}
          />
        </View>
        {/*<View style={styles.saveButtonContainer}>
          <View>
            <Text></Text>
          </View>
          <Button
            onPress={() => {
              saveBrightness();
            }}
            title="Save"
          >
            Save
          </Button>
          </View>*/}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "center",
    //alignItems: "center",
    maxHeight: 100,
    marginLeft: "1%",
    //maxWidth: 400,
    width: "100%",
    minHeight: 100,
    ////marginTop: "2%",
  },
  setMaximumBrightnessContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  sliderContainer: {
    flex: 3,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1%",
  },
  marginLeft: {
    marginLeft: "1%",
  },
});
