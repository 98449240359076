import * as React from "react";

import RootNavigator from "./navigation/RootNavigator";
import { AuthenticatedUserProvider } from "./reducers/auth/AuthStateContext";

export default function App(): JSX.Element {
  return (
    <AuthenticatedUserProvider>
      <RootNavigator></RootNavigator>
    </AuthenticatedUserProvider>
  );
}
