import React from "react";
import { StyleSheet, View, Text, Platform, Pressable } from "react-native";
import i18n from "i18n-js";
import { useTheme, useNavigation } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import { useWindowDimensions } from "react-native";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import { TableStateContext, TableDispatchContext } from "../../../reducers/table/Context";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { decimalToHexColor } from "../../../commonFunctions/colorCalculation";

interface IEffectsProps {
    tableState: GlobalTypes.TableState.In_Game | GlobalTypes.TableState.Idle;
    heading: string;
}

export default function Effects({ tableState, heading }: IEffectsProps): JSX.Element {
    const { appColors }: CustomTheme = useTheme() as CustomTheme;
    const tableConfig: InitialTableState = React.useContext(TableStateContext);
    const dispatch = React.useContext(TableDispatchContext);
    const stateConfiguration = tableConfig.configuration_per_state[tableState];

    const navigation = useNavigation();

    const width = useWindowDimensions().width - useWindowDimensions().width * 0.12;

    const createColorPickers = (count: number, width: number) => {
        const colorPickers = [];

        for (let index = 0; index < count; index++) {
            const colorPicker = (
                <View key={`colorPicker_${index}`} style={styles.colorPickerContainer}>
                    <View style={styles.colorPickerButtonContainer}>
                        <Pressable
                            onPress={() => {
                                const action: Action =
                                    tableState === GlobalTypes.TableState.Idle
                                        ? {
                                            type: "SET_DEFAULT_EFFECT_COLORS_IDLE",
                                            colors: stateConfiguration.default_effect.colors,
                                        }
                                        : {
                                            type: "SET_DEFAULT_EFFECT_COLORS_IN_GAME",
                                            colors: stateConfiguration.default_effect.colors,
                                        };

                                navigation.navigate("ColorModal", {
                                    state: tableState,
                                    action: action,
                                    index: index,
                                    colors: action.colors,
                                });
                            }}
                            style={({ pressed }) => [pressed ? styles.shadow : styles.noShadow, styles.colorButton]}
                        >
                            {() => (
                                <View
                                    style={{
                                        flex: 1,
                                        minHeight: 50,
                                        //minWidth: "100%",
                                        minWidth: 50,
                                        //backgroundColor: "red",
                                        borderRadius: 5,
                                        borderWidth: 1,
                                        backgroundColor: stateConfiguration.default_effect.colors ? decimalToHexColor(stateConfiguration.default_effect.colors[index]) : undefined,
                                    }}
                                ></View>
                            )}
                        </Pressable>
                    </View>

                </View>
            );
            colorPickers.push(colorPicker);
        }

        return colorPickers;
    };

    console.log("colors:")
    console.log(stateConfiguration.default_effect.colors)

    if (
        !tableState ||
        !stateConfiguration.default_effect
        //!stateConfiguration.default_effect.colors
        //stateConfiguration.default_effect.colors.length === 0 ||
        //!stateConfiguration?.default_effect.colors[0]
    ) {
        console.log("return only view");
        return <View></View>;
    } else {
        const colorItems = stateConfiguration
            ? createColorPickers(5, width)
            : null;
        console.log("5 views");
        return (
            <View style={styles.container}>
                <Text style={[appColors.text, commonStyles.textLeft, styles.marginLeft]}>{heading}</Text>
                <View style={styles.colorPickersContainer}>{colorItems}</View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: "center",
        alignItems: "center",
        maxHeight: 100,
        minHeight: 100,
        //maxWidth: 400,
        marginLeft: "1%",
        width: "100%",
        marginBottom: "2%",
        ////marginTop: "2%",
    },
    setMaximumBrightnessContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        width: "90%",
    },
    dropDownContainer: {
        flex: 3,
        minHeight: "10%",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "1%",
        flexDirection: "row",
    },
    marginLeft: {
        marginLeft: "1%",
    },
    colorPickersContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        minWidth: "100%",
        marginTop: "3%"
        //maxHeight: 200,
    },
    colorPickerContainer: {
        minHeight: 73,
        //maxWidth: 400,
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: 40,
        marginBottom: "3%",
        marginHorizontal: 10
        //minWidth: "100%",
    },
    colorPickerButtonContainer: {
        minHeight: 40,
        //maxWidth: 400,
        //width: "85%",
        marginVertical: 4,
        marginLeft: "1%",
        flexDirection: "row"
        //borderRadius: 4,
    },
    shadow: {
        shadowColor: "#000000",
        shadowOffset: {
            width: 1,
            height: 2,
        },
        shadowOpacity: 0.75,
        shadowRadius: 2,
        elevation: 5,
    },
    noShadow: {},
    colorButton: {
        borderRadius: 5,
    },
});
