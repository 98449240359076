import * as React from "react";

import { MaterialIcons } from "@expo/vector-icons";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import Administration from "../features/Administrate/screens/Administration";
import Dashboard from "./DashboardStack";
import StatisticsStack from "./StatisticsStack";
import i18n from "i18n-js";

const Tab = createBottomTabNavigator();

const TabNavigator = () => (
  <Tab.Navigator>
    <Tab.Screen
      name="Main"
      component={Dashboard}
      options={{
        tabBarLabel: i18n.t("Dashboard.Dashboard"),
        tabBarIcon: ({ color }) => <MaterialIcons name="dashboard" size={24} color={color} />,
      }}
    />
    <Tab.Screen
      name="Stats"
      component={StatisticsStack}
      options={{
        tabBarLabel: i18n.t("Statistics"),
        tabBarIcon: ({ color }) => <MaterialIcons name="insights" size={24} color={color} />,
      }}
    />
    <Tab.Screen
      name="Settings"
      component={Administration}
      options={() => ({
        tabBarLabel: i18n.t("Administrate"),
        tabBarIcon: ({ color }) => <MaterialIcons name="build" color={color} size={26} />,
      })}
    />
  </Tab.Navigator>
);

export default TabNavigator;
