import { GameState, ISendApiRequestResponse, TableState } from "../../../../types/GlobalTypes";
import { auth, db } from "../../../../config/firebase";


const updateTableState = (newTableState: TableState, currenGameState: GameState, tableId: number | string | null | null):
    Promise<ISendApiRequestResponse> => {
    return new Promise((resolve, reject) => {
        const userId = auth.currentUser?.uid
        const idPath = tableId !== null ? tableId : "general";
        const state = {
            state: {
                table: newTableState,
                game: currenGameState
            }
        }
        if (__DEV__) {
            console.log("table state that is updated to the cloud:");
            console.log(JSON.stringify(state));
        }
        db.ref(`table_config/${userId}/mcu_read/${idPath}`).
            update(state).then((data) => {
                console.log('data ', data)
                resolve({
                    error: false,
                    code: 0,
                });
            }).catch((error) => {
                console.log('error ', error)
                reject({
                    error: true,
                    code: -1,
                    message: error.message,
                });
            })
    })
}

const updateGameState = (newGameState: GameState, currentTableState: TableState, tableId: number | string | null | null):
    Promise<ISendApiRequestResponse> => {
    return new Promise((resolve, reject) => {
        const userId = auth.currentUser?.uid
        const idPath = tableId !== null ? tableId : "general";
        const state = {
            state: {
                table: currentTableState,
                game: newGameState
            }
        }
        if (__DEV__) {
            console.log("game state that is updated to the cloud:");
            console.log(JSON.stringify(state));
        }
        db.ref(`table_config/${userId}/mcu_read/${idPath}`).
            update(state).then((data) => {
                console.log('data ', data)
                resolve({
                    error: false,
                    code: 0,
                });
            }).catch((error) => {
                console.log('error ', error)
                reject({
                    error: true,
                    code: -1,
                    message: error.message,
                });
            })
    })
}

const updateReset = (reset: boolean, tableId: number | string | null | null):
    Promise<ISendApiRequestResponse> => {
    return new Promise((resolve, reject) => {
        const userId = auth.currentUser?.uid
        const idPath = tableId !== null ? tableId : "general";
        const state = {
            reset: reset
        }
        if (__DEV__) {
            console.log("reset state that is updated to the cloud:");
            console.log(JSON.stringify(state));
        }
        db.ref(`table_config/${userId}/mcu_read/${idPath}`).
            update(state).then((data) => {
                console.log('data ', data)
                resolve({
                    error: false,
                    code: 0,
                });
            }).catch((error) => {
                console.log('error ', error)
                reject({
                    error: true,
                    code: -1,
                    message: error.message,
                });
            })
    })
}

export { updateTableState, updateReset, updateGameState };
