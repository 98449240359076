import "react-native-gesture-handler";
import * as React from "react";
import { useColorScheme } from "react-native";
import { AppearanceProvider } from "react-native-appearance";
import { NavigationContainer } from "@react-navigation/native";
import { TableState, Effect, WifiMode } from "./types/GlobalTypes";
import * as Localization from "expo-localization";
import i18n from "i18n-js";
import { tableReducer, initialTableState } from "./src/reducers/table/Reducer";
import {
  TableStateContext,
  TableDispatchContext,
} from "./src/reducers/table/Context";
import { appReducer, initialAppState } from "./src/reducers/app/AppReducer";
import {
  AppStateContext,
  AppDispatchContext,
} from "./src/reducers/app/AppContext";

/*Th 118n API is mostly synchronous and driven by constants.
On iOS the constants will always be correct, on Android you should check if the locale has updated using AppState and Localization.getLocalizationAsync().
Initially the constants will be correct on both platforms, but on Android a user can change the language and return.
*/

//declare translations
i18n.translations = {
  en: {
    Settings: "settings",
    Administrate: "Administrate",
    Statistics: "Statistics",
    Minutes: "min",
    Seconds: "s",
    Day: "Day",
    Week: "Week",
    Month: "Month",
    Year: "Year",
    In_game: "In game",
    Idle: "Idle",
    Maintenance: "Maintenance",
    Off: "Off",
    January_two_chars: "Ja",
    February_two_chars: "Fe",
    March_two_chars: "Ma",
    April_two_chars: "Ap",
    May_two_chars: "Ma",
    June_two_chars: "Ju",
    July_two_chars: "Ju",
    August_two_chars: "Au",
    September_two_chars: "Sep",
    November_two_chars: "No",
    Dezember_two_chars: "De",
    Save: "Save",
    Table_Not_Reachable_Message:
      "Could not connect to Pongate Pro. Is the table turned on and on the same wifi?",
    Table_Not_Reachable_Title: "Failed to connect to Pongate Pro",
    Table_Configuration_Invalid_Message:
      "The configuration received from Pongate Pro was invalid. If this continues even after reloading the page, please reset the table.",
    Table_Configuration_Invalid_Title: "Invalid configuration response",
    Alert: {
      Try_Again: "Try again",
      Cancel: "Cancel",
      Ok: "Ok",
    },
    Effects: {
      [Effect[Effect.Rainbow_Chase]]: "Rainbow",
      [Effect[Effect.Static_Color]]: "Static color",
      [Effect[Effect.Fade]]: "Pulse",
      [Effect[Effect.Theater_Chase]]: "Theater Chase",
      [Effect[Effect.Color_Wipe]]: "Color wipe",
      [Effect[Effect.Scanner]]: "Scanner",
      [Effect[Effect.None]]: "None",
    },
    TableStates: {
      [TableState[TableState.Idle]]: "Idle",
      [TableState[TableState.Standby]]: "Standby",
      [TableState[TableState.In_Game]]: "In game",
      [TableState[TableState.Maintenance]]: "Maintenance",
    },
    Administration: {
      In_Game: {
        Game_Duration: {
          Limit_Game_Duration: "Limit game duration",
          Heading: "Game duration",
          Not_Allowed_In_Game:
            "Changing the game duration during a game is not allowed. Please switch to idle mode.",
        },
      },
      Maximum_Brightness_Heading: "Set maximum brightness",
      Allowed_Effects: {
        Heading: "Allowed effects",
        Dropdown: {
          Placeholder: "Select effects",
          Multiple_Text: "effect/s is/are active",
        },
      },
      Effect_Configuration_Heading: "Default effect configuration",
      Effect_Colors_Heading: "Default effect colors",
      General_Settings: "General settings",
      Wifi_Mode: {
        Heading: "Wi-Fi mode",
        Undefined_State: "Undefined state",
        [WifiMode[WifiMode.Access_Point]]: "Access Point",
        [WifiMode[WifiMode.Wifi_Connected]]: "Connected to Wi-Fi",
        Reset_Wifi_Mode: "Reset Wi-Fi",
        Reset_Wifi_Mode_Error_Message: "Failed to reset the Wi-Fi.",
        Reset_Wifi_Mode_Error_Message_Title: "Reset failed",
        Reset_Wifi_Mode_Success_Message:
          "Wi-Fi was reset successfully. Please do restart the table now.",
        Reset_Wifi_Mode_Success_Message_Title: "Reset successful",
        Reset_Wifi_Mode_Explanation:
          "You're about to reset the Wi-Fi. This means the current connection method is reset and at the next start of the table, the Wi-Fi mode can be reconfigured. Continue?",
        Reset_Wifi_Mode_Explanation_Title: "Reset Wi-Fi?",
      },
      Save_Button: {
        Error_Changing_In_Game_Configuration_Message:
          "Sorry, failed to save the in game configuration.",
        Error_Changing_In_Game_Configuration_Title:
          "Failed to save configuration",
        Error_Changing_Idle_Configuration_Message:
          "Sorry, failed to save the idle configuration.",
        Error_Changing_Idle_Configuration_Title: "Failed to save configuration",
        Success_Changing_In_Game_Configuration_Message:
          "Successfully saved the in game configuration",
        Success_Changing_In_Game_Configuration_Title:
          "Successfully saved configuration",
        Success_Changing_Idle_Configuration_Message:
          "Successfully saved the idle configuration",
        Success_Changing_Idle_Configuration_Title:
          "Successfully saved configuration",
      },
      Auto_Cycle: {
        Heading: "Auto effect cycling",
        Switch_Text: "Effect cycling",
        Input_Text: "Duration until change",
      },
      Color_Modal: {
        Dismiss_Button_Title: "Dismiss",
        Pick_Color_Button_Title: "Pick color",
        Revert_Button_Title: "Revert",
      },
    },
    Authentication: {
      Email_Placeholder: "Enter email",
      Password_Placeholder: "Enter password",
      Login: "Log in",
      Sign_Up: "Sign up",
      Login_Screen: {
        Title: "Log in",
        Subtitle: "",
        No_Account: "Don't have an account?",
      },
      Sign_Up_Screen: {
        Title: "Create new account",
        Subtitle: "",
        Already_Have_Account: "Already have an account?",
        Success: "Sign up succesful!",
      },
    },
    Dashboard: {
      Dashboard: "Dashboard",
      Session_Timer: {
        Heading: "Playing time",
        Stop_Button_Title: "Stop",
        Clear_Button_Title: "Clear",
        Start_Button_Title: "Start",
        Resume_Button_Title: "Resume",
        Error_Changing_Game_State_Message:
          "Sorry, changing the game state failed.",
        Error_Changing_Game_State_Title: "Failed to change state",
      },
      Power_Button: {
        Not_Reachable: "Not reachable",
        Error_Changing_Table_State_Title: "Failed to change state",
        Error_Changing_Table_State_Message:
          "Sorry, changing the table state failed.",
      },
      Restart_Button: {
        Sub_Title: "Restart",
        Restart_Started_Messages:
          "Restart command was sent to the table. Please wait 30 seconds.",
      },
      Profile_Settings_Screen: {
        Screen_Title: "Profile settings",
      },
    },
    Stats: {
      Battery: {
        Heading: "Battery stats",
      },
      Played_Games: {
        Heading: "Played games",
      },
    },
  },
  de: {
    Settings: "Einstellungen",
    Administrate: "Administrieren",
    Statistics: "Statistiken",
    Minutes: "min",
    Seconds: "s",
    Day: "Tag",
    Week: "Woche",
    Month: "Monat",
    Year: "Jahr",
    In_game: "Im Spiel",
    Maintenance: "Wartung",
    Off: "Ausgeschaltet",
    January_two_chars: "Ja",
    February_two_chars: "Fe",
    March_two_chars: "Mä",
    April_two_chars: "Ap",
    May_two_chars: "Ma",
    June_two_chars: "Ju",
    July_two_chars: "Ju",
    August_two_chars: "Au",
    September_two_chars: "Sep",
    November_two_chars: "No",
    Dezember_two_chars: "De",
    Save: "Speichern",
    Table_Not_Reachable_Message:
      "Pongate Pro ist nicht erreichbar. Ist der Tisch angeschaltet und mit dem Wifi verbunden?",
    Table_Not_Reachable_Title: "Herstellen der Verbindung fehlgeschlagen",
    Table_Configuration_Invalid_Message:
      "Die vom Pongate Pro erhaltene Konfiguration ist ungültig. Wenn dies auch nach einem Neuladen der Seite der Fall bleibt, setzen Sie bitte den Tisch zurück.",
    Table_Configuration_Invalid_Title: "Ungültige Konfiguration erhalten",
    Alert: {
      Try_Again: "Erneut versuchen",
      Cancel: "Abbrechen",
      Ok: "Ok",
    },
    Effects: {
      [Effect[Effect.Rainbow_Chase]]: "Regenbogen",
      [Effect[Effect.Static_Color]]: "Statische Farbe",
      [Effect[Effect.Fade]]: "Pulsierend",
      [Effect[Effect.Theater_Chase]]: "Theater Chase",
      [Effect[Effect.Color_Wipe]]: "Color wipe",
      [Effect[Effect.Scanner]]: "Scanner",
      [Effect[Effect.None]]: "Kein Effekt",
    },
    TableStates: {
      [TableState[TableState.Standby]]: "Standby",
      [TableState[TableState.Idle]]: "Leerlauf",
      [TableState[TableState.In_Game]]: "In-game",
      [TableState[TableState.Maintenance]]: "Wartung",
    },
    Authentication: {
      Email_Placeholder: "E-mail Adresse",
      Password_Placeholder: "Passwort",
      Login: "Anmelden",
      Sign_Up: "Registrieren",
      Login_Screen: {
        Title: "Pongate",
        Subtitle: "",
        No_Account: "Du hast kein Konto?",
      },
      Sign_Up_Screen: {
        Title: "Registiere dich für Pongate",
        Subtitle: "",
        Already_Have_Account: "Du hast bereits ein Konto?",
        Success: "Du hast dich erfolgreich registriert!",
      },
    },
    Administration: {
      In_Game: {
        Game_Duration: {
          Limit_Game_Duration: "Spieldauer begrenzen",
          Heading: "Spieldauer",
          Not_Allowed_In_Game:
            "Während eines Spieles kann das Begrenzen der Spieldauer nicht geändert werden. Bitte dafür in den Leerlauf wechseln.",
        },
      },
      Maximum_Brightness_Heading: "Maximale Helligkeit einstellen",
      Effect_Configuration_Heading: "Standard Effekte konfigurieren",
      Allowed_Effects: {
        Heading: "Aktivierte Effekte",
        Dropdown: {
          Placeholder: "Wähle Effekte aus",
          Multiple_Text: "Effekt/e ist/sind angewählt",
        },
      },
      Effect_Colors_Heading: "Standard Effektfarben",
      General_Settings: "Allgemeine Einstellungen",
      Wifi_Mode: {
        Heading: "WLAN-Modus",
        Undefined_State: "Undefinierter Zustand",
        [WifiMode[WifiMode.Access_Point]]: "Lokaler Access-Point",
        [WifiMode[WifiMode.Wifi_Connected]]: "Verbunden mit Wi-Fi",
        Reset_Wifi_Mode: "WLAN zurücksetzen",
        Reset_Wifi_Mode_Error_Message:
          "Das Zurücksetzen des WLAN ist fehlgeschlagen.",
        Reset_Wifi_Mode_Error_Message_Title: "Zurücksetzen fehlgeschlagen",
        Reset_Wifi_Mode_Success_Message:
          "Das Zurücksetzen des WLAN wurde erfolgreich durchgeführt. Bitte starten Sie den Tisch nun neu.",
        Reset_Wifi_Mode_Success_Message_Title: "Zurücksetzen fehlgeschlagen",
        Reset_Wifi_Mode_Explanation:
          "Sie sind dabei den WLAN zurückzusetzen. Dies hat zur Folge, dass die aktuelle Verbindung zurückgesetzt wird und beim nächsten Start des Tisches eine erneute Konfiguration des WLAN-Modus möglich ist. Fortfahren?",
        Reset_Wifi_Mode_Explanation_Title: "WLAN zurücksetzen?",
      },
      Save_Button: {
        Error_Changing_In_Game_Configuration_Message:
          "Das Speichern der In-game Konfiguration ist fehlgeschlagen",
        Error_Changing_In_Game_Configuration_Title: "Speichern fehlgeschlagen",
        Error_Changing_Idle_Configuration_Message:
          "Das Speichern der Leerlauf-Konfiguration ist fehlgeschlagen",
        Error_Changing_Idle_Configuration_Title: "Speichern fehlgeschlagen",
        Success_Changing_In_Game_Configuration_Message:
          "Speichern der In-game Konfiguration erfolgreich durchgeführt",
        Success_Changing_In_Game_Configuration_Title: "Speichern erfolgreich",
        Success_Changing_Idle_Configuration_Message:
          "Speichern der Leerlauf-Konfiguration erfolgreich durchgeführt",
        Success_Changing_Idle_Configuration_Title: "Speichern erfolgreich",
      },
      Auto_Cycle: {
        Heading: "Auto-Effekt-Wechsel",
        Switch_Text: "Automatisches Umschalten",
        Input_Text: "Dauer bis Wechsel",
      },
      Color_Modal: {
        Dismiss_Button_Title: "Abbrechen",
        Pick_Color_Button_Title: "Farbe wählen",
        Revert_Button_Title: "Zurücksetzen",
      },
    },
    Dashboard: {
      Dashboard: "Dashboard",
      Session_Timer: {
        Heading: "Spieldauer",
        Stop_Button_Title: "Stoppen",
        Clear_Button_Title: "Löschen",
        Start_Button_Title: "Starten",
        Resume_Button_Title: "Fortsetzen",
        Error_Changing_Game_State_Message:
          "Der Spielstatus des Tisches konnte nicht geändert werden.",
        Error_Changing_Game_State_Title: "Fehler beim Schalten.",
      },
      Power_Button: {
        Not_Reachable: "Nicht erreichbar",
        Error_Changing_Table_State_Title: "Fehler beim Schalten",
        Error_Changing_Table_State_Message:
          "Der Status des Tisches konnte nicht geändert werden.",
      },
      Restart_Button: {
        Sub_Title: "Neustart",
        Restart_Started_Messages:
          "Neustart des Tisches wurde gesendet. Bitten warten Sie 30 Sekunden.",
      },
      Profile_Settings_Screen: {
        Screen_Title: "Profile settings",
      },
    },
    Stats: {
      Battery: {
        Heading: "Batterie Statistiken",
      },
      Played_Games: {
        Heading: "Gespielte Partien",
      },
    },
  },
};

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

import { darkTheme, lightTheme } from "./styles/theme.style";

import AppNavigator from "./src/AppNavigator";

export default function App(): JSX.Element {
  const colorScheme = useColorScheme(); //use color scheme hook to set the color scheme based on os settings
  const [state, dispatch] = React.useReducer(tableReducer, initialTableState);
  const [appState, appDispatch] = React.useReducer(appReducer, initialAppState);
  return (
    <AppearanceProvider>
      <NavigationContainer
        theme={colorScheme === "dark" ? darkTheme : lightTheme}
      >
        <AppDispatchContext.Provider value={appDispatch}>
          <AppStateContext.Provider value={appState}>
            <TableDispatchContext.Provider value={dispatch}>
              <TableStateContext.Provider value={state}>
                <AppNavigator />
              </TableStateContext.Provider>
            </TableDispatchContext.Provider>
          </AppStateContext.Provider>
        </AppDispatchContext.Provider>
      </NavigationContainer>
    </AppearanceProvider>
  );
}
