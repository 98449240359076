import Ajv, { JSONSchemaType } from "ajv";
import {
  TableState, WifiMode, GameState, BatteryHealthStatus,
  Effect, ISendApiRequestResponse
} from "../../../../types/GlobalTypes";
import { InitialTableState } from "../../../reducers/table/Reducer";

import { auth, db } from "../../../../config/firebase";
import BatteryStatus from "../components/BatteryStatus";
import { Default_Colors } from "../../../constants/Effects";

interface IConfigurationCheckResponse {
  valid: boolean;
  validationErrors?: any;
  config?: InitialTableState;
}

const fetchCurrentTableConfiguration = (tableId: string | number | null): Promise<IConfigurationCheckResponse> => {
  return new Promise((resolve, reject) => {
    const userId = auth.currentUser?.uid
    const idPath = tableId !== null ? tableId : "general";
    db.ref(`table_config/${userId}/mcu_read/${idPath}`).
      get().then((snapshot) => {
        //success callback
        if (snapshot.exists()) {
          let data = snapshot.val()
          try {

            //rebuild empty arrays from firebase which were not stored
            if (!data.configuration_per_state[TableState.Idle].default_effect.colors) {
              data.configuration_per_state[TableState.Idle].default_effect.colors = Default_Colors
            }
            if (!data.configuration_per_state[TableState.In_Game].default_effect.colors) {
              data.configuration_per_state[TableState.In_Game].default_effect.colors = Default_Colors
            }

          } catch (error) {

          }
          const checkConfigurationResponse = isLegitemateConfiguration(data);
          if (checkConfigurationResponse.valid) {
            const response = {
              config: data,
              valid: true,
            };
            resolve(response);
          } else {
            reject(checkConfigurationResponse);
          }
        }
        else {
          reject({ valid: false });
        }

      }).catch((error) => {
        //error callback
        console.log('error ', error)
      })
  })
}

const createInitialConfigInDb = (
  tableConfig: InitialTableState, tableId: number | string | null | null): Promise<ISendApiRequestResponse> => {
  return new Promise((resolve, reject) => {
    const userId = auth.currentUser?.uid
    const idPath = tableId !== null ? tableId : "general";
    //`table_config/${userId}/mcu_read/${idPath}`
    db.ref(`/table_config/${userId}/mcu_read/${idPath}`).set(tableConfig).then((data) => {
      //success callback
      console.log('data ', data)
      resolve({
        error: false,
        code: 0,
      });
    }).catch((error) => {
      console.log('error ', error)
      reject({
        error: true,
        code: -1,
        message: error.message,
      });
    })
  })
}


/*
{
  "name": "Pongate Pro Prototype",
  "id": 0,
  "state": 1,
  "game_config": {
    "duration": 1200,
    "remaining_duration": 1200,
    "state": 0
  },
  "battery": {
    "cycle_count": 0,
    "level": 0,
    "health": 100,
    "max_voltage": 0,
    "design_capacity": 1000,
    "full_charge_capacity": 1000
  },
  "configuration_per_state": {
    "1": {
      "default_effect": {
        "type": 4,
        "colors": [
          "",
          "",
          "",
          "",
          ""
        ]
      },
      "brightness": 40
    },
    "2": {
      "default_effect": {
        "type": 7,
        "colors": [
          "#0000ff",
          "#ff0000",
          "",
          "",
          ""
        ]
      }
      "brightness": 100,
      "allowed_effects": [
        1,
        2,
        3,
        4,
        6
      ]
    }
  }
}
;*/

const schema: JSONSchemaType<InitialTableState> = {
  type: "object",
  properties: {
    name: { type: "string" },
    id: { type: ["integer", "string"] },
    wifi_mode: {
      type: "number",
      enum: Object.values(WifiMode).filter(k => !isNaN(Number(k))),
    },
    reset: {
      type: "boolean"
    },
    writer_id: {
      type: "number"
    },
    state: {
      type: "object",
      required: ["table", "game"],
      additionalProperties: false,
      properties: {
        game: {
          type: "number",
          enum: Object.values(GameState).filter(k => !isNaN(Number(k)))
        },
        table: {
          type: "string",
          enum: Object.values(TableState).filter(k => isNaN(Number(k)))
        },
        writer_id: {
          type: "number"
        },
      }
    },
    /*battery: {
      type: "object",
      properties: {
        cycle_count: {
          type: "number",
        },
        level: {
          type: "number",
        },
        health: {
          type: "number",
          enum: Object.values(BatteryHealthStatus).filter(k => !isNaN(Number(k)))
        },
        max_voltage: {
          type: "number",
        },
        design_capacity: {
          type: "number",
        },
        full_charge_capacity: {
          type: "number",
        },
      },
      required: [],
      additionalProperties: false,
    },*/

    configuration_per_state: {
      type: "object",
      properties: {
        [`${TableState.Idle}`]: {
          type: "object",
          properties: {
            default_effect: {
              type: "object",
              properties: {
                type: {
                  type: "number",
                  enum: Object.values(Effect).filter(k => !isNaN(Number(k)))
                },
                colors: {
                  type: "array",
                  maxItems: 5,
                  minItems: 0,
                  items: [{ type: "number" }],
                },
              },
              required: ["type", "colors"],
            },
            brightness: {
              type: "number",
            },
            allowed_effects: {
              type: "array",
              items: [{
                type: "number",
                enum: Object.values(Effect).filter(k => !isNaN(Number(k)))  //effects and 0 for not selected
              }],
              maxItems: 10,
              minItems: 1,
            },
            cycling_duration: {
              type: "number"
            },
            effect_cycling: {
              type: "boolean"
            }
          },
          required: ["default_effect", "brightness", "cycling_duration", "effect_cycling", "allowed_effects"],
          additionalProperties: false,
        },
        [`${TableState.In_Game}`]: {
          type: "object",
          properties: {
            default_effect: {
              type: "object",
              properties: {
                type: {
                  type: "number",
                  enum: Object.values(Effect).filter(k => !isNaN(Number(k)))
                },
                colors: {
                  maxItems: 5,
                  minItems: 0,
                  type: "array",
                  items: [{ type: "number" }],
                },
              }
              ,
              required: ["type", "colors"],
            },
            allowed_effects: {
              type: "array",
              items: [{
                type: "number",
                enum: Object.values(Effect).filter(k => !isNaN(Number(k)))
              }],
              maxItems: 10,
              minItems: 1,
            },
            brightness: {
              type: "number",
            },
            limit_game_duration: {
              type: "boolean",
            },
            duration: {
              type: "number",
            },
            remaining_duration: {
              type: "number",
            },
          },
          required: ["allowed_effects", "brightness", "limit_game_duration", "default_effect"],
          additionalProperties: false,
        },
      },
      required: [`${TableState.Idle}`, `${TableState.In_Game}`],
      additionalProperties: false,
    },
  },
  required: ["name", "id", /*"battery",*/ "state", "configuration_per_state", "wifi_mode"],
  additionalProperties: false,
};

const ajv = new Ajv();
const validate = ajv.compile(schema);

const isLegitemateConfiguration = (data: InitialTableState): IConfigurationCheckResponse => {
  try {
    const valid = validate(data);
    const response = {
      valid,
      validationErrors: validate.errors,
    };
    if (!valid) console.log(validate.errors);
    return response;
  } catch (error) {
    console.log(error);
    const response = {
      valid: false,
      validationErrors: validate.errors,
    };
    return response;
  }


};

export { fetchCurrentTableConfiguration, createInitialConfigInDb };
