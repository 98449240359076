import React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Alert,
  Text,
  Platform,
} from "react-native";
import i18n from "i18n-js";
import { useTheme } from "@react-navigation/native";
import commonStyles from "../../../../styles/common.style";
import { CustomTheme } from "../../../../styles/theme.style";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useWindowDimensions } from "react-native";
import { decreaseBrightness } from "../../../commonFunctions/colorCalculation";
import {
  TableDispatchContext,
  TableStateContext,
} from "../../../reducers/table/Context";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { updateReset } from "../logic/SendState";
import { db, auth } from "../../../../config/firebase";

interface IPowerButtonProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PowerButton({
  setIsLoading,
}: IPowerButtonProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);

  const windowWith = useWindowDimensions().width;

  //fetch table state on updates
  React.useEffect(() => {
    const userId = auth.currentUser?.uid;
    const idPath = tableConfig.id !== null ? tableConfig.id : "general";
    const onValueChange = db
      .ref(`/table_config/${userId}/mcu_read/${idPath}/reset`)
      .on("value", (snapshot) => {
        const data = snapshot.val();

        if (__DEV__) {
          console.log(
            `Updating local restart value to ${data} as it changed in db`
          );
        }
        const resetTable: Action = {
          type: "SET_RESET_TABLE",
          reset: data,
        };

        dispatch(resetTable);
      });

    // Stop listening for updates when no longer required
    return () =>
      db
        .ref(`/table_config/${userId}/mcu_read/${idPath}/reset`)
        .off("value", onValueChange);
  }, []);

  React.useEffect(() => {
    // update config in fb when reset value changes
    if (__DEV__) {
      console.log("Send config because of reset value changed.");
    }
    updateReset(tableConfig.reset, tableConfig.id)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [tableConfig.reset]);

  //const size: number = windowWith / 5 > 100 ? 50 : windowWith / 10;
  const size: number =
    windowWith / 5 > 100 ? 50 : ((50 - 30) * (windowWith - 0)) / (450 - 0) + 30;

  let buttonColor = "#b30000";
  let buttonColorPressed = decreaseBrightness(buttonColor, 20);

  const handlePowerButtonPress = async () => {
    const resetTable: Action = {
      type: "SET_RESET_TABLE",
      reset: true,
    };
    if (__DEV__) {
      console.log("Dispatch reset of table");
    }

    alert(i18n.t("Dashboard.Restart_Button.Restart_Started_Messages"));
    dispatch(resetTable);
    setIsLoading(false);
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={({ pressed }) => [
          {
            shadowOpacity: pressed ? 2 : 0,
            textShadowRadius: pressed ? 6 : 0,
            textShadowOffset: pressed
              ? { width: 1, height: 3 }
              : { width: 0, height: 0 },
          },
          styles.button,
        ]}
        onPress={() => {
          handlePowerButtonPress();
        }}
      >
        {({ pressed }) => (
          <Text
            style={
              pressed
                ? {
                    textShadowColor: "black",
                    shadowOpacity: 2,
                    textShadowRadius: 4,
                    textShadowOffset: { width: 2, height: 1 },
                  }
                : {
                    textShadowColor: "black",
                    shadowOpacity: 2,
                    textShadowRadius: 4,
                    textShadowOffset: { width: 0.3, height: 0.2 },
                  }
            }
          >
            <MaterialCommunityIcons
              name="restart"
              size={size}
              color={pressed ? buttonColorPressed : buttonColor}
            />
          </Text>
        )}
      </Pressable>
      <Text
        style={[
          commonStyles.text,
          appColors.text,
          { textAlign: "center" },
          //{ backgroundColor: batteryPercentage ? "" : "silver" },
        ]}
      >
        {i18n.t("Dashboard.Restart_Button.Sub_Title")}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 500,
    minWidth: 100,
  },
  button: {
    padding: "2%", //fixes height of text, because meterial and meterial community icons seem to be not the same sice
  },
});
