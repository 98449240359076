import * as React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import ColorModal from "../features/ChooseColor/screens/ColorModal";

import TabNavigator from "./TabNavigator";

import i18n from "i18n-js";

const MainStack = createStackNavigator();
function MainStackScreen() {
  return (
    <MainStack.Navigator>
      <MainStack.Screen
        name="Root"
        component={TabNavigator}
        options={{
          headerShown: false,
        }}
      />
    </MainStack.Navigator>
  );
}

const HomeStack = createStackNavigator();

const HomeStackScreen = () => (
  <HomeStack.Navigator mode="modal">
    <HomeStack.Screen name="Main" component={MainStackScreen} options={{ headerShown: false }} />
    <HomeStack.Screen
      name="ColorModal"
      options={{
        headerShown: false,
        title: i18n.t("choose color"),
      }}
      component={ColorModal}
    />
  </HomeStack.Navigator>
);

export default HomeStackScreen;
