import React from "react";
import { StyleSheet, View } from "react-native";
import { Button } from "../../../../src/components/Button";
import ColorPicker from "react-native-wheel-color-picker";
import { TableDispatchContext } from "../../../reducers/table/Context";
import { TableState } from "../../../../types/GlobalTypes";
import { StackScreenProps } from "@react-navigation/stack";
import {
  decimalToHexColor,
  hexToDecimalColor,
} from "../../../commonFunctions/colorCalculation";
import i18n from "i18n-js";

type ColorWheelParamList = {
  ColorModal: {
    state: TableState;
    action:
      | {
          type: "SET_DEFAULT_EFFECT_COLORS_IN_GAME";
          colors: number[];
        }
      | {
          type: "SET_DEFAULT_EFFECT_COLORS_IDLE";
          colors: number[];
        };
    colors: number[];
    index: number;
  };
};

type Props = StackScreenProps<ColorWheelParamList, "ColorModal">;

export default function ColorWheel({ route, navigation }: Props): JSX.Element {
  const dispatch = React.useContext(TableDispatchContext);
  const { state, action, colors, index } = route.params;
  const colorPicker = React.useRef<ColorPicker>();

  const [currentColor, setCurrentColor] = React.useState(colors[index]);

  /*const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);*/

  return (
    <View style={[styles.container]}>
      <View style={[styles.container]}>
        <ColorPicker
          ref={colorPicker}
          color={decimalToHexColor(colors[index])}
          //swatchesOnly={this.state.swatchesOnly}
          /*onColorChange={(color) => {
            setCurrentColor(color);
          }}*/
          onColorChangeComplete={(color: string): void => {
            setCurrentColor(hexToDecimalColor(color));
          }}
          thumbSize={40}
          sliderSize={40}
          noSnap={true}
          row={false}

          /*swatchesLast={this.state.swatchesLast}
        swatches={this.state.swatchesEnabled}
        discrete={this.state.disc}*/
        />
      </View>

      <View style={[styles.buttonsContainer]}>
        <View style={styles.dismissButtonContainer}>
          <Button
            onPress={() => navigation.goBack()}
            title={i18n.t("Administration.Color_Modal.Dismiss_Button_Title")}
          />
        </View>

        <View style={styles.dismissButtonContainer}>
          <Button
            onPress={() => {
              console.log(state, action, colors, index);
              const newColors = colors;
              newColors[index] = currentColor;
              console.log(currentColor);
              action.colors = newColors;
              dispatch(action);
              navigation.goBack();
            }}
            title={i18n.t("Administration.Color_Modal.Pick_Color_Button_Title")}
          />
        </View>

        <View style={styles.goBackButtonContainer}>
          <Button
            onPress={() => colorPicker.current.revert()}
            title={i18n.t("Administration.Color_Modal.Revert_Button_Title")}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
    justifyContent: "center",
    //alignItems: "center",
    maxWidth: 500,
    alignSelf: "center",
    width: "95%",
    paddingHorizontal: "1%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  buttonsContainer: {
    marginHorizontal: "1%",
    marginVertical: "4%",
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    flexDirection: "row",
    padding: 0,
  },
  dismissButtonContainer: {
    flex: 1,
    alignSelf: "center",
    margin: "2%",
  },
  goBackButtonContainer: {
    flex: 1,
    alignSelf: "center",
    margin: "2%",
  },
});
