import React from "react";
// as own file to prevent circular reference from files
// two contexts for performence -> https://hswolff.com/blog/how-to-usecontext-with-usereducer/
import { initialTableState, Action } from "./Reducer";

type ICurrentUserContext = [
  Action,
  React.Dispatch<React.SetStateAction<Action>>
];

export const TableStateContext = React.createContext(initialTableState);
export const TableDispatchContext = React.createContext((action: Action) => {
  return;
});
