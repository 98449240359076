import { Theme } from "@react-navigation/native";

/*export interface CustomTheme1 extends Theme {
  dark: boolean;
  colors: {
    button: string;
    placeholder: string;
    primary: string;
    background: string;
    card: string;
    text: string;
    border: string;
    notification: string;
  };
  appColors: {
    primary: string;
    background: {
      backgroundColor: string;
    };
    card: {
      backgroundColor: string;
    };
    button: {
      backgroundColor: string;
      borderColor: string;
    };
    card_2: {
      backgroundColor: string;
    };
    textReversed: {
      color: string;
    };
    text: {
      color: string;
    };
    icons: {
      check: {
        color: string;
      };
    };
    modal: {
      backgroundColor: string;
    };
    input: {
      backgroundColor: string;
      color: string;
    };
    placeholder: {
      color: string;
    };
    searchBar: {
      backgroundColor: string;
    };
  };
}*/

export type CustomTheme = {
  colors: {
    button: string;
    placeholder: string;
  };
  appColors: {
    primary: string;
    background: {
      backgroundColor: string;
    };
    card: {
      backgroundColor: string;
    };
    button: {
      backgroundColor: string;
      borderColor: string;
      pressed: {
        backgroundColor: string;
        borderColor: string;
      };
      shadow: {
        shadowColor: string;
        shadowOpacity: number;
        shadowOffset: { height: number; width: number };
        shadowRadius: number;
      };
    };
    card_2: {
      backgroundColor: string;
    };
    textReversed: {
      color: string;
    };
    text: {
      color: string;
    };
    icons: {
      check: {
        color: string;
      };
    };
    modal: {
      backgroundColor: string;
    };
    input: {
      enabled: {
        backgroundColor: string;
        color: string;
        opacity: number;
        borderColor: string;
      };
      disabled: {
        backgroundColor: string;
        color: string;
        opacity: number;
        borderColor: string;
      };
    };
    placeholder: {
      color: string;
    };
    searchBar: {
      backgroundColor: string;
    };
    separator: {
      color: string;
    };
    btnGroup: {
      selectedBackgroundColor: string;
      backgroundColor: string;
      disabled: {
        opacity: number;
      };
      pressed: {
        opacity: number;
      };
      opacity: number;
    };
  };
} & Theme;

const darkTheme: CustomTheme = {
  dark: true,
  colors: {
    // important for react navigation
    border: "rgb(39, 39, 41)",
    card: "rgb(18, 18, 18)",
    primary: "#0a8b98",
    button: "#0a8b98",
    background: "#191919",
    text: "rgb(229, 229, 231)",
    notification: "rgb(255, 69, 58)",
    placeholder: "rgba(0, 0, 0.0980392,0.22)",
  },
  appColors: {
    card: { backgroundColor: "black" },
    card_2: { backgroundColor: "#414a4c" },
    button: {
      backgroundColor: "hsl(185, 88%, 32%)",
      borderColor: "#0a8b98",
      pressed: {
        backgroundColor: "hsl(185, 88%, 25%)",
        borderColor: "#0a8b98",
      },
      shadow: {
        shadowColor: "#2AC062",
        shadowOpacity: 0.4,
        shadowOffset: { height: 10, width: 0 },
        shadowRadius: 20,
      },
    },
    primary: "#0a8b98",
    background: {
      backgroundColor: "#191919",
    },
    btnGroup: {
      selectedBackgroundColor: "#555555",
      backgroundColor: "#2F2F2F",
      disabled: {
        opacity: 0.8,
      },
      pressed: {
        opacity: 0.5,
      },
      opacity: 1,
    },
    textReversed: {
      color: "rgba(0, 0, 0, 1)",
    },
    text: {
      color: "rgba(255, 255, 255, 0.87)",
    },
    icons: {
      check: {
        color: "#1af01c",
      },
    },
    modal: {
      backgroundColor: "black",
    },
    input: {
      enabled: {
        backgroundColor: "#363636",
        opacity: 1,
        color: "white",
        borderColor: "rgb(223, 223, 223)",
      },
      disabled: {
        backgroundColor: "#363636",
        opacity: 0.6,
        color: "white",
        borderColor: "rgb(223, 223, 223)",
      },
    },
    placeholder: {
      color: "lightgray",
    },
    searchBar: {
      backgroundColor: "#424242",
    },
    separator: {
      color: "#D0D0D0",
    },
  },
};

const lightTheme: CustomTheme = {
  dark: false,
  colors: {
    // important for react navigation
    card: "rgb(255, 255, 255)",
    border: "rgb(216, 216, 216)",
    button: "#0a8b98",
    primary: "#2aabb8",
    text: "rgb(28, 28, 30)",
    notification: "rgb(255, 69, 58)",
    background: "#E2F3FD",
    placeholder: "rgba(0, 0, 0.0980392,0.22)",
    // placeholder: 'rgba(0, 0, 0.0980392,0.22)',
    // text: 'rgb(28, 28, 30)',
  },
  appColors: {
    card: { backgroundColor: "#DFDFDF" },
    card_2: { backgroundColor: "#414a4c" },
    button: {
      backgroundColor: "hsl(185, 63%, 50%)",
      borderColor: "#2aabb8",
      pressed: {
        backgroundColor: "hsl(185, 63%, 40%)",
        borderColor: "#0a8b98",
      },
      shadow: {
        shadowColor: "#2AC062",
        shadowOpacity: 0.4,
        shadowOffset: { height: 10, width: 0 },
        shadowRadius: 20,
      },
    },
    btnGroup: {
      selectedBackgroundColor: "#FFFFFF",
      backgroundColor: "#DFDFDF",
      disabled: {
        opacity: 0.8,
      },
      pressed: {
        opacity: 0.5,
      },
      opacity: 1,
    },
    primary: "#2aabb8",
    text: {
      color: "rgba(0, 0, 0, 1)",
    },
    textReversed: {
      color: "rgba(255, 255, 255, 0.87)",
    },
    background: { backgroundColor: "#E2F3FD" },
    icons: {
      check: { color: "#09910a" },
    },
    modal: {
      backgroundColor: "white",
    },
    input: {
      enabled: {
        backgroundColor: "rgb(250, 250, 250)",
        color: "black",
        borderColor: "rgb(223, 223, 223)",
        opacity: 1,
      },
      disabled: {
        backgroundColor: "rgb(250, 250, 250)",
        color: "black",
        borderColor: "rgb(223, 223, 223)",
        opacity: 0.6,
      },
    },
    separator: {
      color: "#D0D0D0",
    },
    placeholder: {
      color: "grey",
    },
    searchBar: {
      backgroundColor: "#E6E6E6",
    },
  },
};

export { darkTheme, lightTheme };
