enum TableState {
  Standby = "Standby",
  Idle = "Idle",
  In_Game = "In_Game",
  Maintenance = "Maintenance",
}

enum GameState {
  Not_In_Game = 0,
  Running,
  Stopped,
}

enum WifiMode {
  None = 0,
  Wifi_Connected,
  Access_Point,
  Unknown
}

enum TablePart {
  Whole_Table = 0,
  Cup_Triangle,
  Border,
  Logo,
}

enum BatteryHealthStatus {
  Healthy = 0,
  Replacement,
  Failure,
}

enum Effect {
  None = 1,
  Static_Color,
  Rainbow_Chase,
  Theater_Chase,
  Color_Wipe,
  Scanner,
  Fade,
}

enum GameStateCommand {
  Reset = 0,
  Start,
  Stop,
  Resume,
}

enum TimePeriod {
  None = 0,
  Day = 1,
  Week,
  Month,
  Year,
}

export interface ISendApiRequestResponse {
  error: boolean;
  code: number;
  message?: string;
}

export {
  TableState,
  WifiMode,
  TimePeriod,
  BatteryHealthStatus,
  TablePart,
  Effect,
  GameState,
  GameStateCommand,
};
