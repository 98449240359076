import { StyleSheet, Dimensions } from "react-native";

// import ColorScheme from './appearanceMode.style';

const deviceHeight = Dimensions.get("window").height;
const deviceWidth = Dimensions.get("window").width;
const { width, fontScale } = Dimensions.get("window");

function getFontSize(width: number, length: number, max: number) {
  let fontSize = width / length;

  const maxSize = width / max;
  fontSize = Math.min(fontSize, maxSize);

  return fontSize;
}

const FONT_SIZE_X_SMALL: number = 13 / fontScale;
const FONT_SIZE_SMALL: number = 15 / fontScale;
const FONT_SIZE_MEDIUM: number = 16 / fontScale;
const FONT_SIZE_LARGE: number = 17 / fontScale;
const FONT_SIZE_X_LARGE: number = 34 / fontScale;

// dynamic text size depending on display height
/*const FONT_SIZE_X_SMALL: number = deviceHeight < 800 ? 12 * ((900 + 150) / 1000) : 12 * ((deviceHeight + 250) / 1000);
const FONT_SIZE_SMALL: number = deviceHeight < 800 ? 14 * ((900 + 150) / 1000) : 14 * ((deviceHeight + 250) / 1000);
const FONT_SIZE_MEDIUM: number = deviceHeight < 800 ? 16 * ((900 + 150) / 1000) : 16 * ((deviceHeight + 250) / 1000);
const FONT_SIZE_LARGE: number = deviceHeight < 800 ? 18 * ((900 + 150) / 1000) : 18 * ((deviceHeight + 250) / 1000);
const FONT_SIZE_X_LARGE: number = deviceHeight < 800 ? 20 * ((900 + 150) / 1000) : 20 * ((deviceHeight + 250) / 1000);*/
const FONT_WEIGHT_LIGHT = "300";
const FONT_WEIGHT_MEDIUM = "normal";
const FONT_WEIGHT_BOLD = "bold";

const styles = StyleSheet.create({
  textInput: {
    padding: 8,
    margin: 5,
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: 5,
  },
  btnContainer: {
    flex: 1,
    justifyContent: "center",
    margin: 4,
  },
  btn: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.75,
    shadowRadius: 2,
    elevation: 5,
    padding: "0.5%",
    margin: "0.5%",
  },
  headingTextLarge: {
    fontSize: FONT_SIZE_X_LARGE,
    alignSelf: "center",
    padding: "2%",
    fontWeight: FONT_WEIGHT_BOLD,
  },
  headingText: {
    fontSize: FONT_SIZE_LARGE,
    alignSelf: "center",
    padding: "1.5%",
    fontWeight: FONT_WEIGHT_BOLD,
  },
  headingTextLeft: {
    fontSize: FONT_SIZE_LARGE,
    padding: "1.5%",
    fontWeight: FONT_WEIGHT_BOLD,
  },
  text: {
    fontSize: FONT_SIZE_MEDIUM,
    alignSelf: "center",
    //padding: 2,
    margin: "1%",
    fontWeight: FONT_WEIGHT_MEDIUM,
  },
  textLeft: {
    fontSize: FONT_SIZE_MEDIUM,
    padding: "1%",
    //margin: 4,
    fontWeight: FONT_WEIGHT_MEDIUM,
    alignSelf: "flex-start",
  },
  smallText: {
    fontSize: FONT_SIZE_SMALL,
    alignSelf: "center",
    padding: "0.5%",
    fontWeight: FONT_WEIGHT_MEDIUM,
  },
  smallTextLeft: {
    fontSize: FONT_SIZE_SMALL,
    alignSelf: "flex-start",
    padding: "0.5%",
    fontWeight: FONT_WEIGHT_MEDIUM,
  },
  iconButton: {
    fontSize: FONT_SIZE_X_SMALL,
    alignSelf: "center",
    padding: "3%",
    fontWeight: FONT_WEIGHT_MEDIUM,
  },
  icon: {
    fontSize: FONT_SIZE_X_LARGE,
  },
});

export default styles;
