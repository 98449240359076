export enum AggregationType {
  hour = 0,
  day,
  week,
  month,
}

export type PlayedSessions = {
  started: number;
  aborted?: number;
  finished?: number;
  stopped?: number;
  game_duration?: number;
};

export enum GameStatus {
  Started = "started",
  Aborted = "aborted",
  Finished = "finished",
  Stopped = "stopped",
}

// Because JS doesn't have a nice way to name months because they may differ per locale
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const countBy = (
  data: PlayedSessions[],
  gameStatus: GameStatus,
  aggregationType: AggregationType
) => {
  const dataCountedByAggregationType = data.reduce(
    (groupedData: any, unixTimestamp: any) => {
      const dateTime = new Date(unixTimestamp[gameStatus] * 1000);

      if (!(gameStatus in unixTimestamp)) {
        if (__DEV__) {
          console.error(
            "Game status '" +
              gameStatus +
              "' missing in object: " +
              JSON.stringify(unixTimestamp)
          );
        }
      }

      if (aggregationType === AggregationType.month) {
        const dateTime = new Date(unixTimestamp[gameStatus] * 1000);
        const month = dateTime.getMonth();
        const year = dateTime.getFullYear();

        const group = monthNames[month - 1] + " " + year;

        groupedData[group] = (groupedData[group] || 0) + 1; //count per month
      } else if (aggregationType === AggregationType.week) {
        const weekOfYear = getWeekNumber(dateTime);

        const group = weekOfYear + "th week";

        groupedData[group] = (groupedData[group] || 0) + 1; //count per month
      } else if (aggregationType === AggregationType.day) {
        const dayOfWeek = getDayOfWeek(dateTime);

        const group = days[dayOfWeek];

        groupedData[group] = (groupedData[group] || 0) + 1; //count per month
      } else if (aggregationType === AggregationType.hour) {
        const hourOfDay = getHourOfDay(dateTime);

        const group = hourOfDay + "h";

        groupedData[group] = (groupedData[group] || 0) + 1; //count per month
      }

      return groupedData;
    },
    [] //start of groupedData is empty array
  );
  console.log(dataCountedByAggregationType);
  let i = 0;
  const statisticData = Object.entries(dataCountedByAggregationType).reduce(
    (preparedData, currentData) => {
      preparedData[i] = {
        [AggregationType[aggregationType]]: currentData[0],
        playedSessions: currentData[1],
      };
      i = i + 1;
      return preparedData;
    },
    []
  );
  return statisticData;
};

const getWeekNumber = (date: Date) => {
  if (!(date instanceof Date)) date = new Date();

  // ISO week date weeks start on Monday, so correct the day number
  const nDay = (date.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  date.setDate(date.getDate() - nDay + 3);

  // Store the millisecond value of the target date
  const n1stThursday = date.valueOf();

  // Set the target to the first Thursday of the year
  // First, set the target to January 1st
  date.setMonth(0, 1);

  // Not a Thursday? Correct the date to the next Thursday
  if (date.getDay() !== 4) {
    date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  return 1 + Math.ceil((n1stThursday - date) / 604800000);
};

const getDayOfWeek = (date: Date) => {
  if (!(date instanceof Date)) date = new Date();

  // ISO week date weeks start on Monday, so correct the day number
  const nDay = (date.getDay() + 6) % 7;

  return nDay;
};

const getHourOfDay = (date: Date) => {
  if (!(date instanceof Date)) date = new Date();

  return date.getHours();
};
