import { db, auth } from "../../../../config/firebase";
import { AggregationType } from "./aggregateData";

export const getStatisticData = (aggregationType: AggregationType) => {
  const userId = auth.currentUser?.uid;
  const mcuId = 123456789;

  const now = new Date();

  /*const tomorrow = getSecondsSinceEpoch(
    new Date(incrementDate(now, 1).setHours(0, 0, 0, 0))
  );*/

  const path = `/mcu_data/${userId}/${mcuId}/game_history`;

  if (aggregationType == AggregationType.hour) {
    const today = getSecondsSinceEpoch(new Date(now.setHours(0, 0, 0, 0)));
    if (__DEV__) {
      console.log("Getting hourly data");
    }
    return db.ref(path).orderByChild("started").startAt(today).get();
  } else if (aggregationType == AggregationType.day) {
    const lastWeek = getSecondsSinceEpoch(
      incrementDate(new Date(now.setHours(0, 0, 0, 0)), -7)
    );
    if (__DEV__) {
      console.log("Getting dayly data");
    }
    return db.ref(path).orderByChild("started").startAt(lastWeek).get();
  } else if (aggregationType == AggregationType.week) {
    if (__DEV__) {
      console.log("Getting weekly data");
    }
    const lastMonth = getSecondsSinceEpoch(
      incrementDate(new Date(now.setHours(0, 0, 0, 0)), -30)
    );
    return db.ref(path).orderByChild("started").startAt(lastMonth).get();
  } else if (aggregationType == AggregationType.month) {
    const lastYear = getSecondsSinceEpoch(
      incrementDate(new Date(now.setHours(0, 0, 0, 0)), -365)
    );
    if (__DEV__) {
      console.log("Getting monthly data");
    }
    return db.ref(path).orderByChild("started").startAt(lastYear).get();
  } else {
    if (__DEV__) {
      console.error("Unknown aggregationType: " + aggregationType);
    }
  }
};

const incrementDate = (dateInput: Date, increment: number) => {
  const dateFormatTotime = new Date(dateInput);
  const increasedDate = new Date(
    dateFormatTotime.getTime() + increment * 86400000
  );
  return increasedDate;
};

const getSecondsSinceEpoch = (date: Date) => {
  return Math.round(date.getTime() / 1000);
};
