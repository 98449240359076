import { StatusBar } from "expo-status-bar";
import React from "react";
import { useState } from "react";
import { useTheme, useNavigation } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import { StyleSheet, Text, View, Image } from "react-native";
import commonStyles from "../../../../styles/common.style";
import Loading from "../../../components/Loading";
import BackgroundContainer from "../../../components/BackgroundContainer";

import { Button } from "../../../components/Button";
import InputField from "../../../components/InputField";
import ErrorMessage from "../../../components/ErrorMessage";
import { auth } from "../../../../config/firebase";
import i18n from "i18n-js";


export default function SignupScreen() {
  const navigation = useNavigation();
  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState("eye");
  const [signupError, setSignupError] = useState("");

  const handlePasswordVisibility = () => {
    if (rightIcon === "eye") {
      setRightIcon("eye-off");
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === "eye-off") {
      setRightIcon("eye");
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const onHandleSignup = async () => {
    try {
      if (email !== "" && password !== "") {
        setIsLoading(true);
        if (__DEV__) {
          await auth
            .createUserWithEmailAndPassword(email, password)
            .then((userCredential: any) => {
              // Signed in
              const user = userCredential.user;
              alert(i18n.t("Authentication.Sign_Up_Screen.Success"));
              setIsLoading(false);
              // ...
            });
        }
      }
    } catch (error: any) {
      setSignupError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <BackgroundContainer>
      <View style={styles.container}>
        <StatusBar style="auto" />
        <Image
          style={styles.logo}
          source={require("../../../../assets/brand/logo_claim/PONGATE_Logo-Claim_460x180.png")}
        />
        {/*<Text style={[appColors.text, commonStyles.headingText, styles.title]}>
          {i18n.t("Authentication.Sign_Up_Screen.Title")}
  </Text>*/}
        <View style={styles.form}>
          <InputField
            style={styles.inputStyle}
            containerStyle={styles.inputContainerStyle}
            leftIcon="email"
            placeholder={i18n.t("Authentication.Email_Placeholder")}
            autoCapitalize="none"
            keyboardType="email-address"
            textContentType="emailAddress"
            autoFocus={true}
            value={email}
            onChangeText={(text: string) => setEmail(text)}
          />
          <InputField
            style={styles.inputStyle}
            containerStyle={styles.inputContainerStyle}
            leftIcon="lock"
            placeholder={i18n.t("Authentication.Password_Placeholder")}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry={passwordVisibility}
            textContentType="password"
            rightIcon={rightIcon}
            value={password}
            onChangeText={(text: string) => setPassword(text)}
            onRightIconClick={handlePasswordVisibility}
          />
          {signupError ? (
            <ErrorMessage error={signupError} visible={true} />
          ) : null}
          <Button
            onPress={onHandleSignup}
            title={i18n.t("Authentication.Sign_Up")}
            style={styles.signUpButton}
          />
          <Button
            onPress={() => navigation.navigate("Login")}
            title={
              i18n.t("Authentication.Sign_Up_Screen.Already_Have_Account") +
              " " +
              i18n.t("Authentication.Login")
            }
            style={styles.backToLoginButton}
          />
        </View>
      </View>
    </BackgroundContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 500,
    width: "95%",
    paddingHorizontal: 12,
    alignSelf: "center",
  },
  form: {
    marginTop: 12,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    alignSelf: "center",
    paddingBottom: 24,
  },
  inputStyle: {
    fontSize: 14,
  },
  inputContainerStyle: {
    backgroundColor: "#fff",
    marginBottom: 20,
  },
  signUpButton: {
    marginBottom: 10,
  },
  backToLoginButton: {
    backgroundColor: "transparent",
    shadowRadius: 0,
    elevation: 0,
    shadowOffset: { height: 0, widht: 0 },
  },
  logo: {
    //flex: 1,
    height: 180,
    width: undefined,
    resizeMode: "contain",
  },
});
