import React from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import { useTheme } from "@react-navigation/native";
import commonStyles from "../../styles/common.style";
import { CustomTheme } from "../../styles/theme.style";

type ButtonGroupProps = {
  buttons: Array<string>;
  defaultValue: number;
  disabled: boolean;
  getChosenValue?: (clicked: number) => void;
}; /* could also use interface */

export default function ButtonGroup({
  buttons,
  defaultValue,
  disabled,
  getChosenValue,
}: ButtonGroupProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  const [clicked, setClickedId] = React.useState(defaultValue);

  React.useEffect(() => {
    getChosenValue && getChosenValue(clicked), [];
  });

  return (
    <View style={[styles.btnGroupContainer, appColors.btnGroup]}>
      {buttons.map((buttonLabel: string, i: number) => (
        <Pressable
          onPress={() => {
            !disabled && setClickedId(i);
          }}
          style={({ pressed }) => [
            {
              shadowOpacity: clicked === i ? 0 : 0,
              shadowRadius: clicked === i ? 2 : 0,
              shadowOffset: {
                width: 1,
                height: 2,
              },
              elevation: clicked === i ? 5 : 0,
              zIndex: clicked === i ? 1 : 0,
              backgroundColor:
                clicked === i
                  ? appColors.btnGroup.selectedBackgroundColor
                  : appColors.btnGroup.backgroundColor,
              opacity: !disabled
                ? pressed
                  ? appColors.btnGroup.pressed.opacity
                  : appColors.btnGroup.opacity
                : appColors.btnGroup.disabled.opacity,
            },
            styles.btnGroupBtn,
          ]}
          key={i}
        >
          <View
            style={{
              borderRightWidth:
                // hide separators left and right of clicked element and on last element on the right side
                clicked === i || clicked - 1 === i || buttons.length - 1 === i
                  ? 0
                  : 1,
              borderColor: appColors.separator.color,
            }}
          >
            <Text
              style={[appColors.text, commonStyles.smallText, styles.text]}
              adjustsFontSizeToFit
              allowFontScaling
            >
              {buttonLabel}
            </Text>
          </View>
        </Pressable>
      ))}
    </View>
  );
}

const borderColor = "grey";

const styles = StyleSheet.create({
  btnGroupContainer: {
    flexDirection: "row",
    padding: 2,
    borderRadius: 7,
    borderWidth: 0.3,
    borderColor: borderColor,
    marginHorizontal: "0%",
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    alignItems: "stretch", //center inner text and let it fill out the whole outer container group
    justifyContent: "center",
  },
  btnGroupBtn: {
    flex: 1,
    borderRadius: 5,
    padding: "0.5%",
    justifyContent: "center", //center inner text and let it fill out the whole outer container group
    alignItems: "stretch",
  },
  text: { textAlign: "center" },
});
