import React from "react";
import { StyleSheet, View, Text, Platform } from "react-native";
import i18n from "i18n-js";
import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import { TableStateContext, TableDispatchContext } from "../../../reducers/table/Context";
import { InitialTableState } from "../../../reducers/table/Reducer";
import ButtonGroup from "../../../components/ButtonGroup";
import Loading from "../../../components/Loading";
import { db, auth } from "../../../../config/firebase";

export default function WifiMode(): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);

  React.useEffect(() => {
    const userId = auth.currentUser?.uid
    const idPath = tableConfig.id !== null ? tableConfig.id : "general";
    const onValueChange = db
      .ref(`/table_config/${userId}/mcu_read/${idPath}/wifi_mode`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (__DEV__) { console.log('Wifi mode changed in db: ', data); }
        /*if (data.writer_id == tableConfig.writer_id) {
          if (__DEV__) { console.log('Changed value has the same writer id as the app, do not apply it.') }
          return;
        }*/
        dispatch({
          type: "SET_WIFI_MODE",
          wifiMode: data,
        })
      });

    // Stop listening for updates when no longer required
    return () => db.ref(`/table_config/${userId}/mcu_read/${idPath}/wifi_mode`).off('value', onValueChange);
  }, []);

  return (
    <View
      style={[
        styles.container,
        {
          // for dropdown to be over the other elements
          ...(Platform.OS !== "android" && {
            zIndex: 10,
          }),
        },
      ]}
    >
      <View style={styles.btnContainer}>
        <Text style={[appColors.text, commonStyles.textLeft, styles.marginLeft]}>
          {i18n.t("Administration.Wifi_Mode.Heading")}
        </Text>
      </View>
      <View style={styles.btnContainer}>
        <View
          style={[
            styles.btnGroupContainer,
            tableConfig.wifi_mode === GlobalTypes.WifiMode.None || tableConfig.wifi_mode === GlobalTypes.WifiMode.Unknown ? { paddingTop: "0%" } : { paddingTop: "3%" },
          ]}
        >
          {tableConfig.wifi_mode === GlobalTypes.WifiMode.None || tableConfig.wifi_mode === GlobalTypes.WifiMode.Unknown ? (
            <Loading></Loading>
          ) : (
            <ButtonGroup
              buttons={[
                i18n.t("Administration.Wifi_Mode.Wifi_Connected"),
                i18n.t("Administration.Wifi_Mode.Access_Point"),
              ]}
              defaultValue={tableConfig.wifi_mode - 1}
              disabled={true}
            ></ButtonGroup>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: -1,
    //maxWidth: 400,
    width: "100%",
    //marginTop: "2%",
  },
  btnGroupContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    marginLeft: "2%",
    paddingTop: "0%",
  },
  btnContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  marginLeft: {
    marginLeft: "1%",
  },
});
