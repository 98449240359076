import React from "react";
import { StyleSheet, View, Text, Platform, Alert } from "react-native";
import { useTheme } from "@react-navigation/native";
import i18n from "i18n-js";
import { Button } from "../../../../src/components/Button";
import commonStyles from "../../../../styles/common.style";
import { CustomTheme } from "../../../../styles/theme.style";
import {
  TableDispatchContext,
  TableStateContext,
} from "../../../reducers/table/Context";
import {
  TableState,
  GameState,
  GameStateCommand,
} from "../../../../types/GlobalTypes";

import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { AppStateContext } from "../../../reducers/app/AppContext";
import { appReducer, InitialAppState } from "../../../reducers/app/AppReducer";
import Loading from "../../../components/Loading";
import { updateGameState } from "../logic/SendState";

const { useReducer, useRef, useEffect } = React;

/* kapiere ich nicht new State type any */
function reducer(currentState: any, newState: any) {
  return {
    ...currentState,
    ...newState,
  };
}

enum GameTimerState {
  Blinking = 0,
  Running_Down = 1,
}
const blinkDuration = 10;

interface ICountDownProps {
  // setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CountDown(): JSX.Element {
  // setIsLoading,
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);
  const appState: InitialAppState = React.useContext(AppStateContext);

  const inGameConfig = tableConfig.configuration_per_state[TableState.In_Game];
  const [duration, setDuration] = React.useState(1000 * inGameConfig.duration);
  const [gameTimerState, setGameTimerState] = React.useState(
    GameTimerState.Blinking
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const intervalRef = useRef<ReturnType<typeof setTimeout>>(
    setInterval(() => {
      return;
    })
  );

  const countDownStart = 1000 * inGameConfig.duration;
  //const remainingDuration = 1000 * inGameConfig.remaining_duration;
  const gameState = tableConfig.state.game;
  const durationSaved =
    appState.configuration_per_state[TableState.In_Game].game_duration.saved;

  ///send config whenever game state is changing in web app to firebase db
  useEffect(() => {
    if (__DEV__) {
      console.log("Game state changed in web app, send new config to fb");
    }
    updateGameState(gameState, tableConfig.state.table, tableConfig.id)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [gameState]);

  useEffect(() => {
    if (inGameConfig.limit_game_duration === true) {
      if (__DEV__) {
        console.log(
          "Game duration is limited, set back game limit to " + countDownStart
        );
      }
      setDuration(inGameConfig.duration);
      clearInterval(intervalRef.current);
      setState({ lapse: countDownStart, running: false });
    } else {
      if (__DEV__) {
        console.log("Game duration is not limited, set start duration to 0.");
      }
      setDuration(0);
      clearInterval(intervalRef.current);
      setState({ lapse: 0, running: false });
    }
  }, [inGameConfig.limit_game_duration]);

  // immedeatily start timer if game is running
  useEffect(() => {
    if (gameState === GameState.Running) {
      if (__DEV__) {
        console.log("Game state changed to running, start game timer");
      }
      const now = Date.now();

      if (running === false || cleared === true) {
        if (inGameConfig.limit_game_duration === true) {
          intervalRef.current = setInterval(() => {
            setState({ lapse: lapse - Date.now() + now });
          }, 1000);

          setGameTimerState(GameTimerState.Running_Down);
        } else {
          intervalRef.current = setInterval(() => {
            setState({ lapse: lapse + Date.now() - now });
          }, 1000);
        }

        setState({ running: true, cleared: false });
      }
    } else if (gameState === GameState.Stopped) {
      if (__DEV__) {
        console.log("Game state changed to stopped, stop game timer");
      }
      //stop
      if (running !== false) {
        clearInterval(intervalRef.current);
        setState({ running: false });
      }
    } else {
      //reset
      clearInterval(intervalRef.current);
      if (inGameConfig.limit_game_duration === true) {
        setState({ lapse: duration * 1000, running: false, cleared: true });
      } else {
        setState({ lapse: 0, running: false, cleared: true });
      }
    }
    setIsLoading(false);
  }, [gameState]);

  // reset timer if table is turned off / in standby
  useEffect(() => {
    if (tableConfig.state.table === TableState.Standby) {
      if (__DEV__) {
        console.log("Reset game state, as table state is standby.");
      }
      clearInterval(intervalRef.current);
      setGameTimerState(GameTimerState.Blinking);
      if (inGameConfig.limit_game_duration === true) {
        setDuration(countDownStart);
        setState({ lapse: countDownStart, running: false, cleared: true });
      } else {
        setDuration(0);
        setState({ lapse: 0, running: false, cleared: true });
      }
    }
  }, [tableConfig.state]);

  // reset timer if duration time was saved in the settings pane
  useEffect(() => {
    if (durationSaved === true) {
      if (__DEV__) {
        console.log(
          "Reset game duration, as a new time was saved in the settings pane."
        );
      }
      clearInterval(intervalRef.current);
      setGameTimerState(GameTimerState.Blinking);
      if (inGameConfig.limit_game_duration === true) {
        setDuration(countDownStart);
        setState({ lapse: countDownStart, running: false, cleared: true });
      } else {
        setDuration(0);
        setState({ lapse: 0, running: false, cleared: true });
      }

      //data does not need to be sent to fb again here, as its already done in the settings pane on saving
    }
  }, [durationSaved]);

  //initialize timer reducer
  const [{ running, lapse, cleared }, setState] = useReducer(reducer, {
    running: false,
    lapse: duration,
    cleared: true,
  });

  //handling clicks on the start/stop/resume button
  function handleRunClick() {
    setIsLoading(true);
    if (running) {
      // if running --> stop

      if (__DEV__) {
        console.log("Game was running. Stop the game.");
      }

      //update game state
      const setGameState: Action = {
        type: "SET_TABLE_AND_GAME_STATE",
        gameState: GameState.Stopped,
        tableState: TableState.In_Game,
        duration: duration / 1000,
        remainingDuration: lapse / 1000,
      };
      dispatch(setGameState);
      if (__DEV__) {
        console.log(
          "Dispatch game state: ",
          GameState[GameState.Stopped],
          "table state: ",
          TableState.In_Game
        );
      }

      clearInterval(intervalRef.current);
      setState({ running: !running });

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      // if stopped --> start or resume

      if (__DEV__) {
        console.log("Game was stopped --> start or resume game.");
      }

      //update game state
      const setGameState: Action = {
        type: "SET_TABLE_AND_GAME_STATE",
        gameState: GameState.Running,
        tableState: TableState.In_Game,
        duration: duration / 1000,
        remainingDuration: lapse / 1000,
      };

      let now = Date.now();

      //check if started from beginning
      if (cleared && inGameConfig.limit_game_duration === true) {
        //Start blinking
        if (__DEV__) {
          console.log(
            "Start blinking 10 seconds because was started, not resumed."
          );
        }
        //1666727377417
        //set immedeatily to update visual, then interval will update the next cycles
        //only needed here because the initial number is not yet set, its set to duration
        setState({ lapse: blinkDuration * 1000 });
        intervalRef.current = setInterval(() => {
          console.log(
            "current:",
            lapse / 100 - Date.now() + now,
            "now: ",
            now,
            "date.now: ",
            Date.now(),
            "lapse: ",
            lapse
          );
          if (
            lapse / 100 - Date.now() + now <= 1000 &&
            inGameConfig.limit_game_duration == true &&
            gameTimerState === GameTimerState.Blinking
          ) {
            if (__DEV__) {
              console.log(
                "Set game timer state to running down. Set duration to " +
                  duration * 1000
              );
            }
            setGameTimerState(GameTimerState.Running_Down);
            clearInterval(intervalRef.current);
            now = Date.now();

            intervalRef.current = setInterval(() => {
              console.log(
                "current:",
                lapse / 100 - Date.now() + now,
                "now: ",
                now,
                "date.now: ",
                Date.now(),
                "lapse: ",
                lapse
              );
              setState({ lapse: duration * 1000 + 1000 - Date.now() + now });
            }, 1000);
            setState({ lapse: duration * 1000 });
          } else {
            setState({
              lapse: blinkDuration * 1000 + 1000 - Date.now() + now,
            });
          }
        }, 1000);
      } else {
        if (inGameConfig.limit_game_duration === true) {
          if (__DEV__) {
            console.log("Game duration is limited. Start counting down.");
          }

          intervalRef.current = setInterval(() => {
            setState({ lapse: lapse - Date.now() + now });
          }, 1000);
        } else {
          if (__DEV__) {
            console.log(
              "Game duration is not limited. Start counting upwards."
            );
          }
          intervalRef.current = setInterval(() => {
            setState({ lapse: lapse + Date.now() - now });
          }, 1000);
        }
      }

      if (__DEV__) {
        console.log(
          "Dispatch game state: ",
          GameState[GameState.Running],
          "table state: ",
          TableState.In_Game
        );
      }
      dispatch(setGameState);
      setState({ running: true, cleared: false });

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  //clear the timer and set game state back to not in game
  function handleClearClick() {
    setIsLoading(true);

    if (__DEV__) {
      console.log(
        "Handle clear click, reset game duration to " +
          (inGameConfig.limit_game_duration === true
            ? inGameConfig.duration
            : duration / 1000)
      );
    }

    setGameTimerState(GameTimerState.Blinking);

    //update game state
    const setGameState: Action = {
      type: "SET_TABLE_AND_GAME_STATE",
      gameState: GameState.Not_In_Game,
      tableState: TableState.Idle,
      duration:
        inGameConfig.limit_game_duration === true
          ? inGameConfig.duration
          : duration,
      remainingDuration:
        inGameConfig.limit_game_duration === true
          ? inGameConfig.duration
          : duration,
    };

    if (__DEV__) {
      console.log(
        "Dispatch game state: ",
        GameState[GameState.Not_In_Game],
        "table state: ",
        TableState.Idle
      );
    }
    dispatch(setGameState);
    clearInterval(intervalRef.current);
    if (inGameConfig.limit_game_duration === true) {
      setState({ lapse: duration * 1000, running: false, cleared: true });
    } else {
      setState({ lapse: 0, running: false, cleared: true });
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    );
  }

  //console.log("running:", running, "date: ", new Date(lapse).toISOString());

  const time = new Date(lapse).toISOString().slice(14, -5);

  //if blinking get every second red color
  const timerColor =
    inGameConfig.limit_game_duration == true && running
      ? gameTimerState === GameTimerState.Blinking
        ? Math.floor(lapse / 1000) % 2 === 1
          ? appColors.text.color
          : "red"
        : appColors.text.color
      : appColors.text.color;

  return (
    <View style={styles.container}>
      <View style={styles.timer}>
        <Text style={[commonStyles.headingTextLeft, appColors.text]}>
          <Text>{`${i18n.t("Dashboard.Session_Timer.Heading")}: `}</Text>
          <Text style={{ color: timerColor }}>{`${time}`}</Text>
        </Text>
      </View>
      <View style={styles.timer}>
        <View style={styles.buttonContainer}>
          <Button
            title={
              running
                ? i18n.t("Dashboard.Session_Timer.Stop_Button_Title")
                : cleared
                ? i18n.t("Dashboard.Session_Timer.Start_Button_Title")
                : i18n.t("Dashboard.Session_Timer.Resume_Button_Title")
            }
            onPress={handleRunClick}
          />
        </View>

        <View style={styles.buttonContainer}>
          <Button
            title={i18n.t("Dashboard.Session_Timer.Clear_Button_Title")}
            onPress={() => handleClearClick()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //maxHeight: "60%",
    ///justifyContent: "center",
    //alignItems: "flex-start",
    flexDirection: "row",
    maxWidth: 1000,
    //minHeight: "100%",
    //height: "50%",
    minHeight: 100,
    //height: "20%",
    flexWrap: "wrap",
  },
  timer: {
    minHeight: "20%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: 0,
    flexDirection: "row",
    minWidth: 230,
    // marginHorizontal: "2%",
  },
  buttonContainer: {
    minHeight: "8%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "row",
    padding: 0,
    // marginHorizontal: "1%",
  },
});
