import { effects, Default_Colors } from "../../constants/Effects";

import {
  TableState,
  BatteryHealthStatus,
  GameState,
  Effect,
  WifiMode
} from "../../../types/GlobalTypes";

export const initialTableState: InitialTableState = {
  name: "Pongate Pro",
  id: "123456789",
  wifi_mode: WifiMode.Unknown,
  //writer_id: Math.round(Math.random() * 1000000000000000),
  reset: false,
  state: {
    table: TableState.Standby,
    game: GameState.Not_In_Game,
    //writer_id: Math.round(Math.random() * 1000000000000000),
  },
  configuration_per_state: {
    [TableState.Idle]: {
      default_effect: {
        type: effects[TableState.Idle].selectableEffects[0].effectType,
        colors: Default_Colors
      },
      brightness: 40,
      allowed_effects: effects[TableState.Idle].selectableEffects.map(
        (effectConfig) => {
          return effectConfig.effectType;
        }
      ),
      effect_cycling: true,
      cycling_duration: 40  //in seconds
    },
    [TableState.In_Game]: {

      default_effect: {
        type: effects[TableState.In_Game].selectableEffects[0].effectType,
        colors: Default_Colors
      },

      brightness: 80,
      allowed_effects: effects[TableState.In_Game].selectableEffects.map(
        (effectConfig) => {
          return effectConfig.effectType;
        }
      ),
      limit_game_duration: true,
      duration: 20 * 60, //20 minutes,
      remaining_duration: 20 * 60,
    },
  },
};

export type IdleConfig = {
  default_effect: {
    type: Effect | null;
    colors: number[];
  };
  effect_cycling: boolean;
  cycling_duration: number;
  brightness: number;
  allowed_effects: (Effect | 0)[];
};

export type InGameConfig = {
  default_effect: {
    type: Effect | null;
    colors: number[];
  };
  brightness: number;
  allowed_effects: (Effect | 0)[];
  limit_game_duration: boolean;
  duration: number;
  remaining_duration: number;
};

export type InitialTableState = {
  name: string;
  //writer_id: number;
  id: string | number | null; //if null --> general configuration
  wifi_mode: WifiMode;
  reset: boolean;
  state: {
    table: TableState;
    game: GameState;
    //writer_id: number;
  },
  //last_updated: number | null;
  configuration_per_state: {
    [TableState.Idle]: IdleConfig;
    [TableState.In_Game]: InGameConfig;
  };
};

export type Action =
  | {
    type: "SET_BRIGHTNESS_IDLE";
    brightness: number;
  }
  | {
    type: "SET_BRIGHTNESS_IN_GAME";
    brightness: number;
  }
  | {
    type: "SET_DEFAULT_EFFECT_COLORS_IN_GAME";
    colors: number[];
  }
  | {
    type: "SET_DEFAULT_EFFECT_COLORS_IDLE";
    colors: number[];
  }
  | {
    type: "SET_DEFAULT_EFFECT_IDLE";
    effectType: Effect;
  }
  | {
    type: "SET_DEFAULT_EFFECT_IN_GAME";
    effectType: Effect;
  }
  | {
    type: "SET_ALLOWED_EFFECTS";
    allowedEffects: (Effect | 0)[];
    tableState: TableState.In_Game | TableState.Idle
  }
  | {
    type: "SET_GAME_DURATION";
    duration: number;
    limitGameDuration: boolean;
  }
  | {
    type: "SET_WIFI_MODE";
    wifiMode: WifiMode;
  }
  | {
    type: "SET_TABLE_AND_GAME_STATE";
    gameState: GameState;
    tableState: TableState;
    duration: number;
    remainingDuration: number;
  }
  | {
    type: "SET_CONFIGURATION";
    configuration: InitialTableState;
  }
  | {
    type: "SET_IDLE_CONFIGURATION";
    config: IdleConfig;
  }
  | {
    type: "SET_IN_GAME_CONFIGURATION";
    config: InGameConfig;
  }
  | {
    type: "SET_RESET_TABLE";
    reset: boolean;
  }
  | {
    type: "SET_EFFECT_CYCLING_DURATION";
    duration: number;
  }
  | {
    type: "SET_EFFECT_CYCLING";
    value: boolean;
  }
  | { type: "INITIAL_VALUE" }
  | { type: "" }; //do nothing


export const tableReducer = (
  prevState: InitialTableState,
  action: Action
): InitialTableState => {
  switch (action.type) {
    case "SET_BRIGHTNESS_IN_GAME":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.In_Game]: {
            ...prevState.configuration_per_state[TableState.In_Game],
            brightness: action.brightness,
          },
        },
      };
    case "SET_BRIGHTNESS_IDLE":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.Idle]: {
            ...prevState.configuration_per_state[TableState.Idle],
            brightness: action.brightness,
          },
        },
      };

    case "SET_DEFAULT_EFFECT_COLORS_IDLE":
      if (__DEV__) {
        console.log("Setting SET_DEFAULT_EFFECT_COLORS_IDLE.")
      }
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.Idle]: {
            ...prevState.configuration_per_state[TableState.Idle],

            default_effect: {
              ...prevState.configuration_per_state[TableState.Idle]
                .default_effect,
              colors: action.colors,
            },
          },
        },
      };

    case "SET_DEFAULT_EFFECT_COLORS_IN_GAME":
      if (__DEV__) {
        console.log("Setting SET_DEFAULT_EFFESET_DEFAULT_EFFECT_COLORS_IN_GAMECT_COLORS_IDLE.")
      }
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.In_Game]: {
            ...prevState.configuration_per_state[TableState.In_Game],
            default_effect: {
              ...prevState.configuration_per_state[TableState.In_Game]
                .default_effect,
              colors: action.colors,
            },
          },
        },
      };

    case "SET_ALLOWED_EFFECTS":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [action.tableState]: {
            ...prevState.configuration_per_state[action.tableState],
            allowed_effects: action.allowedEffects
          },
        },
      };

    case "SET_DEFAULT_EFFECT_IDLE":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.Idle]: {
            ...prevState.configuration_per_state[TableState.Idle],
            default_effect: {
              ...prevState.configuration_per_state[TableState.Idle]
                .default_effect,
              type: action.effectType,
            },
          },
        },
      };

    case "SET_DEFAULT_EFFECT_IN_GAME":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.In_Game]: {
            ...prevState.configuration_per_state[TableState.In_Game],
            default_effect: {
              ...prevState.configuration_per_state[TableState.In_Game]
                .default_effect,
              type: action.effectType,
            },
          },
        },
      };

    case "SET_GAME_DURATION":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.In_Game]: {
            ...prevState.configuration_per_state[TableState.In_Game],
            duration: action.duration,
            remaining_duration: action.duration,
            limit_game_duration: action.limitGameDuration,
          },
        },
      };

    case "SET_CONFIGURATION":
      return action.configuration;
    case "SET_IDLE_CONFIGURATION":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.Idle]: action.config
        }
      };
    case "SET_IN_GAME_CONFIGURATION":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.In_Game]: action.config
        },
      };
    case "SET_TABLE_AND_GAME_STATE":
      if (__DEV__) {
        console.log("Setting table and game state.")
      }
      return {
        ...prevState,
        state: {
          ...prevState.state,
          game: action.gameState,
          table: action.tableState
        }
      };
    case "SET_WIFI_MODE":
      return {
        ...prevState,
        wifi_mode: action.wifiMode
      };
    case "SET_RESET_TABLE":
      return {
        ...prevState,
        reset: action.reset
      };
    case "SET_EFFECT_CYCLING_DURATION":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.Idle]: {
            ...prevState.configuration_per_state[TableState.Idle],
            cycling_duration: action.duration
          },
        },
      };
    case "SET_EFFECT_CYCLING":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.Idle]: {
            ...prevState.configuration_per_state[TableState.Idle],
            effect_cycling: action.value
          },
        },
      };
    default:
      return prevState;
  }
};
