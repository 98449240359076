import React from "react";
import { StyleSheet, View, Text, Platform } from "react-native";
import i18n from "i18n-js";
import DropDownPicker from "react-native-dropdown-picker";
import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import { effects } from "../../../constants/Effects";
import { TableDispatchContext, TableStateContext } from "../../../reducers/table/Context";
import { AppDispatchContext, AppStateContext } from "../../../reducers/app/AppContext";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { DropDownType } from "../../../reducers/app/AppReducer";

interface IAllowedEffectsProps {
  tableState: GlobalTypes.TableState.In_Game | GlobalTypes.TableState.Idle;
  heading: string;
}

export default function AllowedEffects({ tableState, heading }: IAllowedEffectsProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);
  const appDispatch = React.useContext(AppDispatchContext);
  const appState = React.useContext(AppStateContext);
  const stateConfiguration = tableConfig.configuration_per_state[tableState];
  const [open, setOpen] = React.useState(false);

  const controller = React.useRef(null);

  const allowedEffects = tableConfig.configuration_per_state[tableState].allowed_effects.filter((effect) => effect > 0)

  //set open manually to false so the zIndex will be correct if the dropdown gets closed, because another gets opened
  appState.openedDropDown !==
    (tableState === GlobalTypes.TableState.Idle ?
      DropDownType.Allowed_Effect_Idle : DropDownType.Allowed_Effect_In_Game)
    && controller && controller.current && setOpen(false);

  return (
    <View
      style={[
        styles.container,
        {
          // for dropdown to be over the other elements
          ...(Platform.OS !== "android" && {
            zIndex: open === true ? 11 : 10, //higher zIndex when open, so always above other dropdowns
          }),
        },
      ]}
    >
      <Text style={[appColors.text, commonStyles.textLeft, styles.marginLeft]}>{heading}</Text>
      <View style={styles.setMaximumBrightnessContainer}>
        <View style={styles.dropDownContainer}>
          <DropDownPicker
            multiple={true}  //allow multiple itmes being checked
            controller={instance => controller.current = instance}
            max={7}
            onOpen={() => {
              setOpen(true);
              appDispatch({
                type: "SET_OPENED_DROPDOWN", dropDownType: tableState === GlobalTypes.TableState.Idle ?
                  DropDownType.Allowed_Effect_Idle : DropDownType.Allowed_Effect_In_Game
              })
            }}
            isVisible={appState.openedDropDown ===
              (tableState === GlobalTypes.TableState.Idle ?
                DropDownType.Allowed_Effect_Idle : DropDownType.Allowed_Effect_In_Game)
              ? true : false}
            onClose={() => setOpen(false)}
            placeholder={i18n.t(`Administration.Allowed_Effects.Dropdown.Placeholder`)}
            multipleText={"%d " + i18n.t(`Administration.Allowed_Effects.Dropdown.Multiple_Text`)}
            //create dropdown effects for the table state
            items={effects[tableState].selectableEffects.map((selectableEffect) => {
              return {
                value: selectableEffect.effectType ? selectableEffect.effectType : 0,
                label: selectableEffect.label ? i18n.t(`Effects.${selectableEffect.label}`) : "",
              };
            })}
            // if type exists, use the type from the config as the default type
            defaultValue={
              allowedEffects
            }
            zIndex={tableState === GlobalTypes.TableState.In_Game ? 2000 : 4000}
            zIndexInverse={tableState === GlobalTypes.TableState.In_Game ? 4000 : 5000}
            min={1}
            containerStyle={styles.dropDownContainerStyle}
            style={{ backgroundColor: "#fafafa" }}
            itemStyle={{
              justifyContent: "flex-start",
            }}
            onChangeItemMultiple={(item) => {
              let newAllowedEffects = item.map((i: any) => i.value);
              for (let i = 0; i < effects[tableState].selectableEffects.length - item.length; i++) {
                newAllowedEffects.push(0);
              }
              const action: Action = {
                type: "SET_ALLOWED_EFFECTS",
                allowedEffects: newAllowedEffects,
                tableState: tableState,
              }
              if (__DEV__) {
                console.log("new effects: " + newAllowedEffects.join(", "))
                console.log(newAllowedEffects);
              }
              dispatch(action);
            }}
            //placeholder="Select an effect"
            dropDownStyle={{ backgroundColor: "#fafafa" }}
          />
        </View>
        {/*<View style={styles.saveButtonContainer}>
          <Button
            onPress={() => {
              return;
            }}
            title="Save"
          >
            Save
          </Button>
          </View> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "center",
    alignItems: "center",
    maxHeight: 100,
    minHeight: 100,
    //maxWidth: 400,
    marginLeft: "1%",
    width: "100%",
    ////marginTop: "2%",
  },
  setMaximumBrightnessContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
  },
  dropDownContainer: {
    flex: 3,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1%",
  },
  marginLeft: {
    marginLeft: "1%",
  },
  dropDownContainerStyle: {
    height: 40,
    minWidth: "100%",
  },
});
