import { useTheme } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useWindowDimensions } from "react-native";
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryContainer,
} from "victory-native";
import commonStyles from "../../../../styles/common.style";
import { getStatisticData } from "../logic/getStatisticData";
import { CustomTheme } from "../../../../styles/theme.style";
import Loading from "../../../components/Loading";
import i18n from "i18n-js";
import {
  AggregationType,
  PlayedSessions,
  countBy,
  GameStatus,
} from "../logic/aggregateData";

//import { useFonts } from "expo-font";

type StatsOfPlayedSessionsProps = {
  aggregationType: AggregationType;
}; /* could also use interface */

export default function StatsOfPlayedSessions({
  aggregationType,
}: StatsOfPlayedSessionsProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(true);
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const currentWidth = useWindowDimensions().width;
  const currentHeight = useWindowDimensions().height;

  const [playedSessions, setPlayedSession] = React.useState<PlayedSessions[]>(
    []
  );

  React.useEffect(() => {
    console.log("get statistic data for aggregationType: " + aggregationType);
    const result = getStatisticData(aggregationType);
    result &&
      result
        .then((snapshot) => {
          if (snapshot.exists()) {
            const snapShotValues = snapshot.val();
            const fetchedValues: PlayedSessions[] =
              Object.values(snapShotValues);

            setPlayedSession(fetchedValues);
          } else {
            if (__DEV__) {
              console.log(
                "Could not find any data in db for aggregationType: " +
                  aggregationType
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [aggregationType]);

  console.log(playedSessions);

  const firstMonth = new Date(
    Math.min(playedSessions.map((data) => data.started).values())
  ).getMonth();
  const lastMonth = new Date(
    Math.max(playedSessions.map((data) => data.started).values())
  ).getMonth();

  const groupedData =
    playedSessions.length > 0
      ? countBy(playedSessions, GameStatus.Started, aggregationType)
      : [];

  console.log(groupedData);

  const data = [
    { month: "Ja", playedSessions: 50 },
    { month: "Fe", playedSessions: 70 },
    { month: "Mä", playedSessions: 73 },
    { month: "Ap", playedSessions: 80 },
  ];
  console.log(data);

  let maxWidth: string | number = 500;

  if (currentWidth > 900 && currentWidth < 1500) {
    maxWidth = (currentWidth * 0.9) / 2;
  } else if (currentWidth > 1500) {
    maxWidth = (currentWidth * 0.7) / 2;
  } else {
    maxWidth = currentWidth;
  }

  const loadingWidth = { width: maxWidth, minHeight: 320 };

  if (isLoading === true) {
    // return loading screen, if data is loading
    return (
      <View style={[styles.container, loadingWidth]}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={[styles.container, loadingWidth]}>
      <Text style={[commonStyles.text, appColors.text]}>
        {i18n.t("Stats.Played_Games.Heading")}
      </Text>
      <VictoryChart
        //width={currentWidth > 320 ? 600 : currentWidth - 20}
        height={currentHeight / 3}
        width={maxWidth}
        //maxDomain={2000}

        domainPadding={{ x: currentWidth / 12 }}
        theme={VictoryTheme.material}
        containerComponent={
          //allows scrolling in web on touch devices
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              userSelect: "auto",
              touchAction: "auto",
            }}
          />
        }
        /**
         * the material theme uses the Roboto font, and react-native-svg isn't
         * compatible with expo-font, so we can't use this theme:
         * theme={VictoryTheme.material}
         **/
      >
        <VictoryBar
          data={groupedData}
          barRatio={0.7}
          x={groupedData[0] ? Object.keys(groupedData[0])[0] : ""}
          y="playedSessions"
          //alignment="middle"
          animate={{
            duration: 500,
            onLoad: { duration: 500 },
          }}
          //barRatio={0.8}
        />
      </VictoryChart>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1000,
    marginHorizontal: "2%",
    alignItems: "center",
    justifyContent: "center",
    //marginTop: "2%",
    //flexDirection: "column",
    /*minHeight: 250,
    maxHeight: 700,
    maxWidth: 700,
    minWidth: 320,*/
    //flexGrow: 1,
  },
});
