import React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Alert,
  Text,
  Platform,
} from "react-native";
import i18n from "i18n-js";
import { useTheme } from "@react-navigation/native";
import commonStyles from "../../../../styles/common.style";
import { CustomTheme } from "../../../../styles/theme.style";
import { MaterialIcons } from "@expo/vector-icons";
import { useWindowDimensions } from "react-native";
import { decreaseBrightness } from "../../../commonFunctions/colorCalculation";
import {
  TableDispatchContext,
  TableStateContext,
} from "../../../reducers/table/Context";
import { TableState, GameState } from "../../../../types/GlobalTypes";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { updateTableState } from "../logic/SendState";
import { db, auth } from "../../../../config/firebase";

interface IPowerButtonProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PowerButton({
  setIsLoading,
}: IPowerButtonProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);

  const windowWith = useWindowDimensions().width;
  const tableState = tableConfig.state.table;

  React.useEffect(() => {
    // update config in fb when table state changes
    if (__DEV__) {
      console.log("Send config because of table state changed.");
    }
    updateTableState(tableState, tableConfig.state.game, tableConfig.id)
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [tableState]);

  //const size: number = windowWith / 5 > 100 ? 50 : windowWith / 10;
  const size: number =
    windowWith / 5 > 100 ? 50 : ((50 - 30) * (windowWith - 0)) / (450 - 0) + 30;

  let buttonColor = "#8c8c8c";
  let buttonColorPressed = decreaseBrightness(buttonColor, 5);

  if (tableState === TableState.In_Game) {
    buttonColor = "#00b300";
  } else if (tableState === TableState.Standby) {
    buttonColor = "#b30000";
  } else if (tableState === TableState.Idle) {
    buttonColor = "#F7DC6F";
  } else if (tableState === TableState.Maintenance) {
    buttonColor = "#FF5733";
  }

  if (buttonColor === "#8c8c8c") {
    buttonColorPressed = buttonColor;
  } else {
    buttonColorPressed = decreaseBrightness(buttonColor, 20);
  }

  const failedAlert = () => {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      Alert.alert(
        i18n.t("Dashboard.Power_Button.Error_Changing_Table_State_Title"),
        i18n.t("Dashboard.Power_Button.Error_Changing_Table_State_Message"),
        [
          {
            text: i18n.t("Alert.Try_Again"),
            onPress: () => handlePowerButtonPress(),
          },
          {
            text: i18n.t("Alert.Cancel"),
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel",
          },
        ]
      );
    } else {
      alert(
        i18n.t("Dashboard.Power_Button.Error_Changing_Table_State_Message")
      );
    }
  };

  const handlePowerButtonPress = async () => {
    //send table into standby for every state except if in standby than go to idle
    const stateToSend =
      tableState === TableState.Idle ||
      tableState === TableState.In_Game ||
      tableState === TableState.Maintenance
        ? TableState.Standby
        : TableState.Idle;
    console.log(tableState);
    console.log(stateToSend);
    setIsLoading(true);

    const setTableState: Action = {
      type: "SET_TABLE_AND_GAME_STATE",
      gameState: tableConfig.state.game,
      tableState: stateToSend,
      duration:
        tableConfig.configuration_per_state[TableState.In_Game].duration,
      remainingDuration:
        tableConfig.configuration_per_state[TableState.In_Game]
          .remaining_duration,
    };
    if (__DEV__) {
      console.log(
        "Dispatch game state: ",
        GameState[tableConfig.state.game],
        "table state: ",
        stateToSend
      );
    }
    dispatch(setTableState);

    setIsLoading(false);
  };

  let buttonSubText = i18n.t("Dashboard.Power_Button.Not_Reachable");
  switch (tableState) {
    case TableState.In_Game:
      buttonSubText = i18n.t("TableStates." + TableState[TableState.In_Game]);
      break;
    case TableState.Standby:
      buttonSubText = i18n.t("TableStates." + TableState[TableState.Standby]);
      break;
    case TableState.Idle:
      buttonSubText = i18n.t("TableStates." + TableState[TableState.Idle]);
      break;
    case TableState.Maintenance:
      buttonSubText = i18n.t(
        "TableStates." + TableState[TableState.Maintenance]
      );
      break;
    default:
      break;
  }

  return (
    <View style={styles.container}>
      <Pressable
        style={({ pressed }) => [
          {
            shadowOpacity: pressed ? 2 : 0,
            textShadowRadius: pressed ? 6 : 0,
            textShadowOffset: pressed
              ? { width: 1, height: 3 }
              : { width: 0, height: 0 },
          },
          styles.button,
        ]}
        onPress={() => {
          handlePowerButtonPress();
        }}
      >
        {({ pressed }) => (
          <Text
            style={
              pressed
                ? {
                    textShadowColor: "black",
                    shadowOpacity: 2,
                    textShadowRadius: 4,
                    textShadowOffset: { width: 2, height: 1 },
                  }
                : {
                    textShadowColor: "black",
                    shadowOpacity: 2,
                    textShadowRadius: 4,
                    textShadowOffset: { width: 0.3, height: 0.2 },
                  }
            }
          >
            <MaterialIcons
              name="power-settings-new"
              size={size}
              color={pressed ? buttonColorPressed : buttonColor}
            />
          </Text>
        )}
      </Pressable>
      <Text
        style={[
          commonStyles.text,
          appColors.text,
          { textAlign: "center" },
          //{ backgroundColor: batteryPercentage ? "" : "silver" },
        ]}
      >
        {buttonSubText}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 500,
    minWidth: 100,
  },
  button: {
    padding: "0%",
  },
});
