import React, { useEffect } from "react";
import { StyleSheet, View, Platform, Alert } from "react-native";
import { useTheme } from "@react-navigation/native";
import i18n from "i18n-js";
import { TableState } from "../../../../types/GlobalTypes";
import { CustomTheme } from "../../../../styles/theme.style";
import { Button } from "../../../components/Button";
import { TableStateContext } from "../../../reducers/table/Context";
import { InitialTableState } from "../../../reducers/table/Reducer";

import { AppDispatchContext } from "../../../reducers/app/AppContext";
import { AppAction, DropDownType } from "../../../reducers/app/AppReducer";
import { sendConfig } from "../../Dashboard/logic/SendConfig";

interface ISaveButtonProps {
  tableState: TableState.In_Game | TableState.Idle;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SaveConfigurationButton({
  tableState,
  setIsLoading,
}: ISaveButtonProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const appDispatch = React.useContext(AppDispatchContext);
  // const dispatch = React.useContext(TableDispatchContext);

  const alertResponse = (message: string, title: string) => {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      Alert.alert(title, message, [
        {
          text: i18n.t("Alert.Try_Again"),
          onPress: () => sendConfigurationPerState(),
        },
        {
          text: i18n.t("Alert.Cancel"),
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ]);
    } else {
      alert(message);
    }
  };

  const sendConfigurationPerState = () => {
    const errorMessage =
      tableState === TableState.Idle
        ? i18n.t(
            "Administration.Save_Button.Error_Changing_Idle_Configuration_Message"
          )
        : i18n.t(
            "Administration.Save_Button.Error_Changing_In_Game_Configuration_Message"
          );
    const errorTitle =
      tableState === TableState.Idle
        ? i18n.t(
            "Administration.Save_Button.Error_Changing_Idle_Configuration_Title"
          )
        : i18n.t(
            "Administration.Save_Button.Error_Changing_In_Game_Configuration_Title"
          );

    const successMessage =
      tableState === TableState.Idle
        ? i18n.t(
            "Administration.Save_Button.Success_Changing_Idle_Configuration_Message"
          )
        : i18n.t(
            "Administration.Save_Button.Success_Changing_In_Game_Configuration_Message"
          );
    const successTitle =
      tableState === TableState.Idle
        ? i18n.t(
            "Administration.Save_Button.Success_Changing_Idle_Configuration_Title"
          )
        : i18n.t(
            "Administration.Save_Button.Success_Changing_In_Game_Configuration_Title"
          );

    setIsLoading(true);

    //reset dropdowns
    const appAction: AppAction = {
      type: "SET_OPENED_DROPDOWN",
      dropDownType: DropDownType.None,
    };

    appDispatch(appAction);

    if (tableState === TableState.Idle) {
      if (__DEV__) {
        console.log("Send idle config.");
      }
      sendConfig(tableConfig, tableConfig.id)
        .then((response) => {
          if (response.code !== 0) {
            alertResponse(errorMessage, errorTitle);
          } else {
            alertResponse(successMessage, successTitle);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alertResponse(errorMessage, errorTitle);
          setIsLoading(false);
        });
    } else if (tableState === TableState.In_Game) {
      {
        if (__DEV__) {
          console.log("Send in game config.");
        }
        sendConfig(tableConfig, tableConfig.id)
          .then((response) => {
            if (response.code !== 0) {
              alertResponse(errorMessage, errorTitle);
            } else {
              // duration was saved
              // this value is needed at the duration at the dashboard and should trigger a reset there
              const appAction: AppAction = {
                type: "SET_GAME_DURATION_SAVED",
                saved: true,
              };

              appDispatch(appAction);
              alertResponse(successMessage, successTitle);
              // setSavedDuration(true);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            alertResponse(errorMessage, errorTitle);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <View style={styles.container}>
      <Button
        onPress={() => {
          sendConfigurationPerState();
        }}
        style={styles.saveButton}
        title={i18n.t("Save")}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: "2%",
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1,
    marginBottom: "4%",
    width: "100%",
    //maxWidth: 400,
  },
  saveButton: {
    alignItems: "center",
    margin: "1%",
    minWidth: 103,
    maxWidth: 150,
  },
});
