import React from "react";
// as own file to prevent circular reference from files
// two contexts for performence -> https://hswolff.com/blog/how-to-usecontext-with-usereducer/
import { initialAppState, AppAction } from "./AppReducer";

type ICurrentUserContext = [
  AppAction,
  React.Dispatch<React.SetStateAction<AppAction>>
];

export const AppStateContext = React.createContext(initialAppState);
export const AppDispatchContext = React.createContext((action: AppAction) => {
  return;
});
