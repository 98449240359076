import React from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import { useTheme } from "@react-navigation/native";
import BackgroundContainer from "./BackgroundContainer";
import { CustomTheme } from "../../styles/theme.style";

const Loading = function Loading() {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  return (
    <BackgroundContainer>
      <View style={styles.loadingContainer}>
        <ActivityIndicator testID="Loading" size="large" color={appColors.primary} />
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
  },
});

export default Loading;
