import React from "react";
import { StyleSheet, View, TextInput, Text, Platform } from "react-native";
import i18n from "i18n-js";

import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import {
  TableDispatchContext,
  TableStateContext,
} from "../../../reducers/table/Context";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { AppDispatchContext } from "../../../reducers/app/AppContext";
import { AppAction } from "../../../reducers/app/AppReducer";
import { Switch } from "react-native-gesture-handler";
import { sendConfig } from "../../Dashboard/logic/SendConfig";

interface IGameDurationProps {
  heading: string;
}

export default function GameDuration({
  heading,
}: IGameDurationProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);
  const appDispatch = React.useContext(AppDispatchContext);
  const stateConfiguration =
    tableConfig.configuration_per_state[GlobalTypes.TableState.In_Game];

  const [duration, setDuration] = React.useState(
    (stateConfiguration.duration / 60).toString()
  );

  // if the duration gets changed from firebase in the global state, adapt it here
  React.useEffect(() => {
    if (__DEV__) {
      //console.log("Update duration in text field to", (stateConfiguration.duration / 60).toString())
    }
    setDuration((stateConfiguration.duration / 60).toString());
  }, [stateConfiguration.duration]);

  const onChangeText = (text: string) => {
    //Do not allow greater games than 60 min
    if (text && parseInt(text) > 60) {
      setDuration("60");
      return;
    }
    setDuration(text);
    // if a valid number is set, dispatch it to the app state
    if (text && !isNaN(parseInt(text))) {
      if (__DEV__) {
        console.log("Set duration to " + parseInt(text) * 60);
      }
      const action: Action = {
        type: "SET_GAME_DURATION",
        duration: parseInt(text) * 60,
        limitGameDuration: stateConfiguration.limit_game_duration,
      };

      dispatch(action);

      //duration is not saved anymore, but changed
      //this value is needed at the duration at the dashboard
      const appAction: AppAction = {
        type: "SET_GAME_DURATION_SAVED",
        saved: false,
      };

      appDispatch(appAction);
    }
  };

  const isEnabled = stateConfiguration.limit_game_duration;
  const toggleLimitGameDuration = () => {
    //do not allow toggling when in game, because the the game time cannot be handled then
    if (tableConfig.state.game === GlobalTypes.GameState.Running) {
      alert(i18n.t("Administration.In_Game.Game_Duration.Not_Allowed_In_Game"));
      return;
    }
    if (__DEV__) {
      console.log("Set duration to " + parseInt(duration) * 60);
    }
    const action: Action = {
      type: "SET_GAME_DURATION",
      duration: parseInt(duration) * 60,
      limitGameDuration: !stateConfiguration.limit_game_duration,
    };

    dispatch(action);

    //duration is not saved anymore, but changed
    //this value is needed at the duration at the dashboard
    const appAction: AppAction = {
      type: "SET_GAME_DURATION_SAVED",
      saved: false,
    };

    appDispatch(appAction);
  };

  return (
    <View style={styles.container}>
      <Text style={[appColors.text, commonStyles.textLeft, styles.marginLeft]}>
        {heading}
      </Text>
      <View style={styles.switchContainer}>
        <View style={styles.switchTextContainer}>
          <Text
            style={[
              appColors.text,
              commonStyles.smallTextLeft,
              styles.marginLeft,
              styles.switchText,
            ]}
          >
            {i18n.t("Administration.In_Game.Game_Duration.Limit_Game_Duration")}
          </Text>
        </View>
        <View style={styles.switchItemContainer}>
          <Switch
            trackColor={{ false: "#767577", true: "#81b0ff" }}
            thumbColor={isEnabled ? appColors.primary : "#f4f3f4"}
            ios_backgroundColor="#3e3e3e"
            onValueChange={toggleLimitGameDuration}
            value={isEnabled}
          ></Switch>
        </View>
      </View>
      <View style={styles.setDurationContainer}>
        <TextInput
          style={[
            commonStyles.textInput,
            styles.textInput,
            isEnabled ? appColors.input.enabled : appColors.input.disabled,
          ]}
          defaultValue={(stateConfiguration.duration / 60).toString()}
          maxLength={2}
          keyboardType={"number-pad"}
          onChangeText={onChangeText}
          value={duration}
          editable={isEnabled}
        ></TextInput>
        <View>
          <Text
            style={[
              appColors.text,
              commonStyles.smallText,
              isEnabled
                ? { opacity: appColors.input.enabled.opacity }
                : { opacity: appColors.input.disabled.opacity },
            ]}
          >
            {i18n.t("Minutes")}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "center",
    alignItems: "center",
    //maxHeight: 100,
    //minHeight: 100,
    marginTop: "2%",
    marginBottom: "3%",
    //maxWidth: 400,
    width: "100%",
  },
  setDurationContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "1%",
    width: "90%",
  },
  marginLeft: {
    marginLeft: "1%",
  },
  textInput: {
    height: 40,
    flex: 1,
    //width: "84%",
  },

  switchContainer: {
    flex: 1,
    flexDirection: "row",
    margin: "1%",
    //marginTop: "2%",
    //marginBottom: "4%",
    width: "90%",
  },
  switchTextContainer: {
    marginLeft: "1%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  switchText: {
    alignSelf: "center",
  },
  switchItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 0,
  },
});
