import React from "react";
import { StyleSheet, View, Platform, Alert } from "react-native";
import BackgroundContainer from "../../../components/BackgroundContainer";
import { useTheme, useNavigation } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import { IconButton } from "../../../components/IconButton";
import commonStyles from "../../../../styles/common.style";
import i18n from "i18n-js";
import Loading from "../../../components/Loading";
import { TableDispatchContext, TableStateContext } from "../../../reducers/table/Context";
import { InitialTableState } from "../../../reducers/table/Reducer";
import { db, auth } from "../../../../config/firebase";

import SessionTime from "../components/SessionTime";
import BatteryStatus from "../components/BatteryStatus";
import PowerButton from "../components/PowerButton";
import RestartButton from "../components/RestartButton";
//import TableVisual from "../components/TableVisual";

import { fetchCurrentTableConfiguration, createInitialConfigInDb } from "../logic/LoadInitialStateFromServer";
import { GameState, TableState } from "../../../../types/GlobalTypes";

export default function Settings(): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(true);
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);
  const navigation = useNavigation();
  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  //fetch table state on updates
  React.useEffect(() => {
    const userId = auth.currentUser?.uid
    const idPath = tableConfig.id !== null ? tableConfig.id : "general";
    const onValueChange = db
      .ref(`/table_config/${userId}/mcu_read/${idPath}/state`)
      .on("value", snapshot => {
        const data = snapshot.val()

        //if (data.writer_id != tableConfig.writer_id) {
        //if (__DEV__) { console.log("Updating local state, as writer id differs", data.writer_id, tableConfig.writer_id) }

        //if (tableState !== data) {
        if (__DEV__) { console.log("Table state data changed in fb: ", data); }
        if (__DEV__) { console.log("Game state: ", GameState[data.game]); }
        dispatch({
          type: "SET_TABLE_AND_GAME_STATE",
          gameState: data.game,
          tableState: data.table,
          duration: tableConfig.configuration_per_state.In_Game.duration,
          remainingDuration: tableConfig.configuration_per_state.In_Game.remaining_duration
        })
        /*}
        else {
          if (__DEV__) { console.log("Table state data changed in fb, but has same writer_id:", data, tableState); }
        }*/
        setIsLoading(false);
      });

    // Stop listening for updates when no longer required
    return () => db.ref(`/table_config/${userId}/mcu_read/${idPath}/state`).off("value", onValueChange);
  }, []);

  const [tableState, setTableState] = React.useState(TableState.Standby);

  //fetch configuration when the dashboard is opened
  React.useEffect(() => {
    fetchCurrentTableConfiguration(tableConfig.id).then((data) => {
      if (data.valid === true && data.config) {
        if (__DEV__) {
          console.log("Dispatching whole config loaded from fb on first load.")
        }
        dispatch({
          type: "SET_CONFIGURATION",
          configuration: data.config,
        });
      } else {
        let errMessage = i18n.t("Table_Not_Reachable_Message");
        let errTitle = i18n.t("Table_Not_Reachable_Title");

        if (data.validationErrors) {
          errMessage = i18n.t("Table_Configuration_Invalid_Message");
          errTitle = i18n.t("Table_Configuration_Invalid_Title");
        }

        if (Platform.OS === "ios" || Platform.OS === "android") {
          Alert.alert(errTitle, errMessage, [
            {
              text: i18n.t("Alert.Ok"),
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel",
            },
          ]);
        } else {
          alert(errMessage);
        }
      }
      setIsLoading(false);
    }).catch((data) => {

      const errMessage = i18n.t("Table_Configuration_Invalid_Message");
      const errTitle = i18n.t("Table_Configuration_Invalid_Title");

      if (Platform.OS === "ios" || Platform.OS === "android") {
        Alert.alert(errTitle, errMessage, [
          {
            text: i18n.t("Alert.Ok"),
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel",
          },
        ]);
      } else {
        alert(errMessage);
      }
      createInitialConfigInDb(tableConfig, tableConfig.id).then((data) => { setIsLoading(false); })
        .catch(() => {
          setIsLoading(false);
        })

    });
  }, []);

  //fetch table state on updates
  /*React.useEffect(() => {
    const userId = auth.currentUser?.uid
    const idPath = tableConfig.id !== null ? tableConfig.id : "general";
    const onValueChange = db
      .ref(`/table_config/${userId}/mcu_read/${idPath}/table_state/state`)
      .on("value", snapshot => {
        const data = snapshot.val()
        console.log("User data: ", data);
        setTableState(snapshot.val())
      });

    // Stop listening for updates when no longer required
    return () => db.ref(`/mcu_data/${tableConfig.id}`).off("value", onValueChange);
  }, []);*/

  if (isLoading === true) {
    // return loading screen, if data is loading
    return <Loading />;
  }

  return (
    <BackgroundContainer>
      <View style={styles.container}>
        <View style={styles.settingsButtonContainer}>
          <IconButton
            name={"account-box"}
            color="#808080"
            onPress={() => {
              navigation.navigate("ProfileSettings");
            }}
          ></IconButton>
        </View>
        <View style={styles.statusContainer}>
          <View style={styles.wrapContainer}>
            <View style={styles.batteryPercentageContainer}>
              <BatteryStatus batteryPercentage={null}></BatteryStatus>
            </View>
            <View style={styles.stateButtonsContainer}>
              <PowerButton setIsLoading={setIsLoading}></PowerButton>
              <RestartButton setIsLoading={setIsLoading}></RestartButton>
            </View>
          </View>
        </View>
        <View style={styles.sessionTimeContainer}>
          <SessionTime ></SessionTime>
        </View>
        {/*<View style={styles.tableVisualContainer}>
          <TableVisual></TableVisual>
  </View> */}
      </View>
    </BackgroundContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    //alignItems: "center",
  },
  settingsButtonContainer: {
    //flex:1,
    marginRight: "3%",
    alignItems: "flex-end",
  },
  statusContainer: {
    flex: 1,
    marginHorizontal: "20%",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center"
  },
  wrapContainer: {
    maxHeight: 300,
    justifyContent: "center",
    height: 200,
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap",
    alignItems: "center"
  },
  sessionTimeContainer: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  tableVisualContainer: {
    flex: 12,
    flexDirection: "row",
  },
  stateButtonsContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 185
  },
  batteryPercentageContainer: {
    flex: 1,
    minWidth: 185,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "row",
  }
});
