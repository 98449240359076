import * as React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import Statistics from "../features/Statistics/screens/Statistics";
import i18n from "i18n-js";

const StatsStack = createStackNavigator();

const StatsStackScreen = () => {
  return (
    <StatsStack.Navigator>
      <StatsStack.Screen
        name="Stats"
        component={Statistics}
        options={{
          headerShown: false,
          title: i18n.t("statistics"),
        }}
      />
    </StatsStack.Navigator>
  );
};

export default StatsStackScreen;
