import React, { useState, createContext, ReactNode } from "react";

export const AuthenticatedUserContext = createContext({});

declare interface AuthenticatedUserProviderProps {
  children: ReactNode;
}

export const AuthenticatedUserProvider = ({ children }: AuthenticatedUserProviderProps) => {
  const [user, setUser] = useState(null);

  return <AuthenticatedUserContext.Provider value={{ user, setUser }}>{children}</AuthenticatedUserContext.Provider>;
};
