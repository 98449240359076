import React from "react";
import { StyleSheet, View, TextInput, Text, Platform } from "react-native";
import i18n from "i18n-js";
import DropDownPicker from "react-native-dropdown-picker";
import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import { TableDispatchContext, TableStateContext } from "../../../reducers/table/Context";
import { InitialTableState, Action } from "../../../reducers/table/Reducer";
import { AppDispatchContext } from "../../../reducers/app/AppContext";
import { AppAction } from "../../../reducers/app/AppReducer";
import { Switch } from "react-native-gesture-handler";

interface IAutoCylingProps {
  heading: string;
}

export default function AutoCyling({ heading }: IAutoCylingProps): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);
  const appDispatch = React.useContext(AppDispatchContext);
  const stateConfiguration = tableConfig.configuration_per_state[GlobalTypes.TableState.Idle];

  const [duration, setDuration] = React.useState((stateConfiguration.cycling_duration).toString());

  // if the duration gets changed from firebase in the global state, adapt it here
  React.useEffect(() => {
    if (__DEV__) {
      //console.log("Update duration in text field to", (stateConfiguration.duration / 60).toString())
    }
    setDuration((stateConfiguration.cycling_duration).toString());
  }, [stateConfiguration.cycling_duration]);

  const onChangeText = (text: string) => {
    //Do not allow greater cycling then 5 minutes
    if (!text) {
      return;
    }
    if (text && parseInt(text) > 300) {
      text = "300";
    }
    else if (isNaN(parseInt(text))) {
      text = "40";
    }
    setDuration(text);
    // if a valid number is set, dispatch it to the app state
    if (text && !isNaN(parseInt(text)) && parseInt(text) > 19) {
      const action: Action = {
        type: "SET_EFFECT_CYCLING_DURATION",
        duration: parseInt(text),
      };

      dispatch(action);
    }
  };

  const isEnabled = stateConfiguration.effect_cycling;
  const toggleLimitGameDuration = () => {
    const action: Action = {
      type: "SET_EFFECT_CYCLING",
      value: !stateConfiguration.effect_cycling,
    };

    dispatch(action);
  };

  //red bordercolor if under 20sec
  const borderColor = !isNaN(parseInt(duration)) && parseInt(duration) < 20 ? "red" : isEnabled ? appColors.input.enabled.borderColor : appColors.input.disabled.borderColor;

  return (
    <View style={styles.container}>
      <Text style={[appColors.text, commonStyles.textLeft, styles.marginLeft]}>{heading}</Text>
      <View style={styles.switchContainer}>
        <View style={styles.switchTextContainer}>
          <Text style={[appColors.text, commonStyles.smallTextLeft, styles.marginLeft, styles.switchText]}>
            {i18n.t("Administration.Auto_Cycle.Switch_Text")}
          </Text>
        </View>
        <View style={styles.switchItemContainer}>
          <Switch
            trackColor={{ false: "#767577", true: "#81b0ff" }}
            thumbColor={isEnabled ? appColors.primary : "#f4f3f4"}
            ios_backgroundColor="#3e3e3e"
            onValueChange={toggleLimitGameDuration}
            value={isEnabled}
          ></Switch>
        </View>
      </View>

      <View style={styles.durationContainer}>
        <View style={styles.durationHeadlineContainer}>
          <Text style={[appColors.text, commonStyles.smallTextLeft, styles.marginLeft]}>{i18n.t("Administration.Auto_Cycle.Input_Text")}</Text>
        </View>
        <View style={styles.durationInputContainer}>
          <TextInput
            style={[
              commonStyles.textInput,
              styles.textInput,
              isEnabled ? appColors.input.enabled : appColors.input.disabled,
              { borderColor: borderColor }
            ]}
            defaultValue={(stateConfiguration.cycling_duration / 60).toString()}
            maxLength={4}
            keyboardType={"number-pad"}
            onChangeText={onChangeText}
            value={duration}
            editable={isEnabled}
          ></TextInput>
          <View>
            <Text
              style={[
                appColors.text,
                commonStyles.smallText,
                isEnabled ? { opacity: appColors.input.enabled.opacity } : { opacity: appColors.input.disabled.opacity },
              ]}
            >
              {i18n.t("Seconds")}
            </Text>
          </View>
        </View>

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "center",
    alignItems: "center",
    //maxHeight: 100,
    //minHeight: 100,
    marginTop: "2%",
    marginBottom: "3%",
    //maxWidth: 400,
    width: "100%",
  },
  durationContainer: {
    flex: 1,
    width: "90%",
    marginLeft: "1%",
  },
  durationHeadlineContainer: {
    flex: 5,
  },
  durationInputContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  marginLeft: {
    marginLeft: "1%",
  },
  textInput: {
    height: 40,
    flex: 1,
    //width: "84%",
  },

  switchContainer: {
    flex: 1,
    flexDirection: "row",
    margin: "1%",
    //marginTop: "2%",
    //marginBottom: "4%",
    width: "90%",
  },
  switchTextContainer: {
    marginLeft: "1%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  switchText: {
    alignSelf: "center",
  },
  switchItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 0,
  },
});
