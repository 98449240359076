import React from "react";
import { StyleSheet, View, Text, Dimensions } from "react-native";
import BackgroundContainer from "../../../components/BackgroundContainer";
import { useTheme } from "@react-navigation/native";
import Svg, { G, Rect, Path } from "react-native-svg";
import { CustomTheme } from "../../../../styles/theme.style";
import commonStyles from "../../../../styles/common.style";
import { useWindowDimensions } from "react-native";

interface IBatteryProps {
  batteryPercentage: number | null;
}

/*function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
}*/

export default function BatteryStatus({ batteryPercentage }: IBatteryProps) {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  const windowWith = useWindowDimensions().width;

  const width: number =
    windowWith / 5 > 100
      ? 100
      : ((70 - 50) * (windowWith - 0)) / (150 - 0) + 50;
  const batteryText = batteryPercentage ? batteryPercentage + "%" : "?%";

  return (
    <View style={styles.container}>
      <Svg width={width} height={200} viewBox="0 0 204 225">
        <G stroke="#000">
          <Rect
            width={143.623}
            height={62.047}
            x={30.242}
            y={82.336}
            ry={11.979}
            fillRule="evenodd"
            strokeWidth={0.22}
            strokeLinejoin="bevel"
            fill={batteryPercentage ? "A0A0A0" : "#A9A9A9"}
          />
          <Path
            d="M173.841 116.835c3.154-.485 3.776.89 3.682-3.147.132-5.898-1.133-3.279-3.688-4.287"
            fill={appColors.text.color}
            strokeWidth={0.23}
          />
          <Path
            d="M173.932 117.207c3.45-.576 4.13.93 4.027-3.517.144-6.502-1.24-3.598-4.035-4.676"
            fill="none"
            strokeWidth={0.252}
          />

          <Rect
            width={141.49}
            height={59.989}
            x={31.347}
            y={83.319}
            ry={11.582}
            fill={batteryPercentage ? "#fff" : "#C0C0C0"}
            fillRule="evenodd"
            strokeWidth={0.214}
            strokeLinejoin="bevel"
          />
          <Rect
            width={batteryPercentage ? (141.49 * batteryPercentage) / 100 : 0}
            height={59.989}
            x={31.347}
            y={83.319}
            rx={11.582}
            ry={11.582}
            fill={
              batteryPercentage && batteryPercentage > 20 ? "#5EFD55" : "red"
            }
            fillRule="evenodd"
            strokeWidth={0.214}
            strokeLinejoin="bevel"
          />
        </G>
      </Svg>

      <Text
        style={[
          commonStyles.text,
          appColors.text,
          styles.batteryPercentage,
          //{ backgroundColor: batteryPercentage ? "" : "silver" },
        ]}
      >
        {batteryText}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxHeight: 50,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    maxWidth: 500,
    minWidth: 100,
  },
  batteryPercentage: {
    minWidth: 25,
  },
});
