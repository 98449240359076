import React from "react";
import { StyleSheet, View, Platform, Alert } from "react-native";
import { useTheme } from "@react-navigation/native";
import i18n from "i18n-js";
import { CustomTheme } from "../../../../styles/theme.style";
import { Button } from "../../../components/Button";
import { sendWifiModeReset } from "../logic/SendGeneralConfiguration";

import { TableStateContext } from "../../../reducers/table/Context";
import { InitialTableState } from "../../../reducers/table/Reducer";

interface IResetWifiModeButton {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ResetWifiModeButton({ setIsLoading }: IResetWifiModeButton): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);

  const alertResponse = (message: string, title: string): void => {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      Alert.alert(title, message, [
        {
          text: i18n.t("Alert.Try_Again"),
          onPress: () => sendWifiMode(),
        },
        {
          text: i18n.t("Alert.Cancel"),
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ]);
    } else {
      alert(message);
    }
  };

  const confirmReset = () => {
    const message = i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode_Explanation");
    const title = i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode_Explanation_Title");
    if (Platform.OS === "ios" || Platform.OS === "android") {
      Alert.alert(title, message, [
        {
          text: i18n.t(message),
          onPress: () => sendWifiMode(),
        },
        {
          text: i18n.t("Alert.Cancel"),
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ]);
    } else {
      const result = confirm(message);
      if (result == true) {
        sendWifiMode();
      }
    }
  };

  const sendWifiMode = () => {
    const errorMessage = i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode_Error_Message");
    const errorTitle = i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode_Error_Message_Title");

    const successMessage = i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode_Success_Message");

    const successTitle = i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode_Success_Message_Title");

    setIsLoading(true);

    sendWifiModeReset(tableConfig.id)
      .then((response) => {
        if (response.code !== 0) {
          alertResponse(errorMessage, errorTitle);
        } else {
          alertResponse(successMessage, successTitle);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alertResponse(errorMessage, errorTitle);
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      <Button
        onPress={() => {
          confirmReset();
        }}
        style={styles.saveButton}
        title={i18n.t("Administration.Wifi_Mode.Reset_Wifi_Mode")}
      ></Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: "6%",
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1,
    marginBottom: "2%",
    maxWidth: 450,
    width: "100%",
  },
  saveButton: {
    alignItems: "center",
    textAlign: "center",
    margin: "1%",
    //minWidth: 103,
    //maxWidth: 350,
    minWidth: "68%",
  },
});
