import React, { ReactNode } from "react";
import { StyleSheet, StatusBar, SafeAreaView, useColorScheme, Platform } from "react-native";

import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../styles/theme.style";

declare interface AppProps {
  children: ReactNode;
  style?: Record<string, unknown>;
}

export default function BackgroundContainer({ children, style }: AppProps) {
  // Container for backgroundcolor and to provide offline screen to every screen
  // Child props have to be passed because otherwise they won't render

  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  const colorScheme = useColorScheme();

  //const marginHorizontal: string = useWindowDimensions().width > 1000 ? "5%" : "0%";
  const marginHorizontal = "0.1%";

  const themeStatusBarStyle = colorScheme === "light" ? "dark-content" : "light-content";

  //const marginTop = Platform.OS !== "ios" ? "2%" : "2%";
  const marginTop = "2%";

  return (
    <SafeAreaView
      style={[styles.container, appColors.background, { marginHorizontal: marginHorizontal }, { marginTop }, style]}
    >
      {children}
      <StatusBar barStyle={themeStatusBarStyle} />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: "1%",
    width: "100%",
    //left: "10%",
    maxWidth: 1500,
    alignSelf: "center",
    //maxWidth: 1000,
    // paddingTop: Constants.statusBarHeight,
  },
});
