import { TableState } from "../../../types/GlobalTypes";

export enum DropDownType {
  None = 0,
  Default_Effect_Idle,
  Default_Effect_In_Game,
  Allowed_Effect_Idle,
  Allowed_Effect_In_Game,
}

export const initialAppState: InitialAppState = {
  configuration_per_state: {
    [TableState.Idle]: {},
    [TableState.In_Game]: {
      game_duration: {
        saved: false,
      },
    },
  },
  description: "",
  location: "",
  openedDropDown: DropDownType.None

};

export type InitialAppState = {
  configuration_per_state: {
    [TableState.Idle]: Record<string, never>;
    [TableState.In_Game]: {
      game_duration: {
        saved: boolean;
      };
    };
  };
  description: string;
  location: string;
  openedDropDown: DropDownType;

};

export type AppAction =
  | {
    type: "SET_GAME_DURATION_SAVED";
    saved: boolean;
  }
  | {
    type: "SET_OPENED_DROPDOWN";
    dropDownType: DropDownType;
  }
  | { type: "INITIAL_VALUE" }
  | { type: "" }; //do nothing



export const appReducer = (
  prevState: InitialAppState,
  action: AppAction
): InitialAppState => {
  switch (action.type) {
    case "SET_GAME_DURATION_SAVED":
      return {
        ...prevState,
        configuration_per_state: {
          ...prevState.configuration_per_state,
          [TableState.In_Game]: {
            ...prevState.configuration_per_state[TableState.In_Game],
            game_duration: {
              saved: action.saved,
            },
          },
        },
      };

    case "SET_OPENED_DROPDOWN":
      return {
        ...prevState,
        openedDropDown: action.dropDownType
      };

    default:
      return prevState;
  }
};
