import { WifiMode, ISendApiRequestResponse } from "../../../../types/GlobalTypes";
import { auth, db } from "../../../../config/firebase";


/*const sendWifiModeReset = (): Promise<ISendApiRequestResponse> => {
  const url = __DEV__
    ? "http://localhost:3000/api/v1/tableConfiguration/resetWifiMode/"
    : "http://pongate.local/api/v1/tableConfiguration/resetWifiMode/";
  const fetchConfig = {
    method: "PUT", // Method itself
    headers: {
      "Content-type": "application/json; charset=UTF-8", // Indicates the content
    },
  };

  // make the HTTP put request using fetch api
  return fetch(url, fetchConfig)
    .then((response) => {
      if (!response.ok) throw response;
      return response.json();
    })
    .then(({ code }) => {
      return {
        error: code === 0 ? false : true,
        code: code,
      };
    })
    .catch(() => {
      return {
        error: true,
        code: -1,
      };
    });
};*/

const sendWifiModeReset = (tableId: string | number | null): Promise<ISendApiRequestResponse> => {
  return new Promise((resolve, reject) => {
    const userId = auth.currentUser?.uid
    const idPath = tableId !== null ? tableId : "general";
    db.ref(`table_config/${userId}/mcu_read/${idPath}/`).
      update({ wifi_mode: WifiMode.None }).then((data) => {
        console.log('data ', data)
        resolve({
          error: false,
          code: 0,
        });
      }).catch((error) => {
        console.log('error ', error)
        reject({
          error: true,
          code: -1,
          message: error.message,
        });
      })
  })
}

export { sendWifiModeReset };
