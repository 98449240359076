import React from "react";
import { StyleSheet, View } from "react-native";
import BackgroundContainer from "../../../components/BackgroundContainer";
import i18n from "i18n-js";
import ButtonGroup from "../../../components/ButtonGroup";
import StatsOfRevenue from "../components/StatsOfRevenue";
import StatsOfPlayedSessions from "../components/StatsOfPlayedSessions";
import StatsOfBattery from "../components/StatsOfBattery";
import PlayedSessionsHeatMap from "../components/PlayedSessionsHeatMap";
import { ScrollView } from "react-native-gesture-handler";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import { Platform } from "react-native";
import { AggregationType } from "../logic/aggregateData";

export default function Settings(): JSX.Element {

  const [aggregationType, setAggregationType] = React.useState(AggregationType.day);

  const buttonGroupValue = (clicked: number) => {
    setAggregationType(clicked)
  }
  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer} style={styles.scrollView}>
      <BackgroundContainer style={styles.backgroundContainer}>
        <View style={styles.buttonGroupContainer}>
          <ButtonGroup
            buttons={[i18n.t("Day"), i18n.t("Week"), i18n.t("Month"), i18n.t("Year")]}
            defaultValue={GlobalTypes.TimePeriod.Week}
            disabled={false}
            getChosenValue={buttonGroupValue}
          />
        </View>
        <View style={styles.graphGroupContainer}>
          <View style={styles.graphContainer}>
            <StatsOfPlayedSessions aggregationType={aggregationType}></StatsOfPlayedSessions>
          </View>
          <View style={styles.graphContainer}>
            <StatsOfBattery timePeriod={GlobalTypes.TimePeriod.Week}></StatsOfBattery>
          </View>
        </View>
      </BackgroundContainer>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "4%",
    marginTop: Platform.OS === "web" ? 0 : "2%", // web already has margin at top by default
    marginHorizontal: "1%",
  },
  backgroundContainer: {
    flex: 1,
    paddingBottom: "1%",
    marginBottom: "3%",
  },
  graphGroupContainer: {
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    minWidth: "100%",
    paddingTop: "3%",
    alignSelf: "center",
    //alignItems: "center",
  },
  graphContainer: {
    //maxHeight: Platform.OS === "web" ? 320 : undefined,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    //alignSelf: "flex-start",
    //maxHeight: 300,
    //width: 600,
    //width: "100%",
  },
  scrollView: {
    flexGrow: 1,
    flex: 1,
  },
  buttonGroupContainer: {
    width: "100%",
    paddingTop: "0%",
    alignItems: "center",
    alignSelf: "center",
  },
});
