import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { useWindowDimensions } from "react-native";
import Loading from "../../../components/Loading";
import { VictoryLine, VictoryChart, VictoryTheme, VictoryContainer } from "victory-native";
import commonStyles from "../../../../styles/common.style";
import { useTheme } from "@react-navigation/native";
import { CustomTheme } from "../../../../styles/theme.style";
import * as GlobalTypes from "../../../../types/GlobalTypes";
import { JsxElement } from "typescript";
import i18n from "i18n-js";

interface IStatsOfBattery {
  timePeriod: GlobalTypes.TimePeriod;
}

export default function StatsOfBattery({ timePeriod }: IStatsOfBattery): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(true);
  const { appColors }: CustomTheme = useTheme() as CustomTheme;

  const currentWidth = useWindowDimensions().width;
  const currentHeight = useWindowDimensions().height;
  const [batteryData, setBatteryData] = React.useState({});

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const data = [
    { x: 0, y: 50 },
    { x: 1, y: 70 },
    { x: 2, y: 73 },
    { x: 4, y: 80 },
    { x: 5, y: 80 },
    { x: 6, y: 83 },
    { x: 7, y: 87 },
    { x: 8, y: 86 },
    { x: 9, y: 80 },
    { x: 10, y: 75 },
    { x: 11, y: 66 },
    { x: 12, y: 60 },
    { x: 13, y: 50 },
    { x: 14, y: 40 },
    { x: 15, y: 30 },
    { x: 16, y: 20 },
    { x: 17, y: 17 },
    { x: 18, y: 10 },
  ];

  const processedData = data.map((data) => {
    return {
      x: data.x,
      y: `${data.y}%`,
    };
  });


  let maxWidth: string | number = 500;

  if (currentWidth > 900 && currentWidth < 1500) {
    maxWidth = (currentWidth * 0.9) / 2;
  } else if (currentWidth > 1500) {
    maxWidth = (currentWidth * 0.7) / 2;
  } else {
    maxWidth = currentWidth;
  }

  const loadingWidth = { width: maxWidth, minHeight: 320 };

  if (isLoading === true) {
    // return loading screen, if data is loading
    return (
      <View style={[styles.container, { width: maxWidth }]}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={[styles.container, loadingWidth]}>
      <Text style={[commonStyles.text, appColors.text]}>{i18n.t("Stats.Battery.Heading")}</Text>
      <VictoryChart
        //width={currentWidth > 600 ? 600 : currentWidth - 20}
        height={currentHeight / 3}
        width={maxWidth}
        minDomain={{ y: 0 }}
        //={400}

        maxDomain={{ y: 100 }}
        theme={VictoryTheme.material}
        //domainPadding={25}
        containerComponent={
          //allows scrolling in web on touch devices
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              userSelect: "auto",
              touchAction: "auto",
            }}
          />
        }
      /**
       * the material theme uses the Roboto font, and react-native-svg isn't
       * compatible with expo-font, so we can't use this theme:
       * theme={VictoryTheme.material}
       **/
      >
        <VictoryLine
          style={{
            data: { stroke: ({ data }) => (data.y < 20 ? "red" : "green") },
            parent: { border: "1px solid #ccc" },
          }}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          data={data}
        />
      </VictoryChart>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    //minWidth: "100%",
    alignItems: "stretch",
    justifyContent: "center",
    //flexDirection: "column",
    //marginTop: "2%",
    marginHorizontal: "2%",
    maxWidth: 1000,
    //flexDirection: "row",
    /*minHeight: 250,
    minWidth: 320,
    maxHeight: 300,
    maxWidth: 500,*/
    //flexGrow: 1,
  },
});
