import * as React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import Dashboard from "../features/Dashboard/screens/Dashboard";
import ProfileSettings from "../features/Dashboard/screens/ProfileSettings";
import i18n from "i18n-js";

const DashBoardStack = createStackNavigator();

export default function DashBoardStackScreen() {
  return (
    <DashBoardStack.Navigator>
      <DashBoardStack.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          headerShown: false,
          title: i18n.t("Dashboard.Dashboard"),
        }}
      />
      <DashBoardStack.Screen
        name="ProfileSettings"
        component={ProfileSettings}
        options={{
          headerShown: true,
          title: i18n.t("Dashboard.Profile_Settings_Screen.Screen_Title"),
        }}
      />
    </DashBoardStack.Navigator>
  );
}
