import { TableState, Effect } from "../../types/GlobalTypes";

const Default_Colors: number[] = [255, 16711680, 65280, 15597395, 749961]

//Effect configuration
interface IEffectConfiguration {
  effectType: Effect;
  label: string;
  neededColorPickers: number;
  initialColors: number[];
}

const None: IEffectConfiguration = {
  effectType: Effect.None,
  label: Effect[Effect.None],
  neededColorPickers: 0,
  initialColors: [],
};

const Rainbow: IEffectConfiguration = {
  effectType: Effect.Rainbow_Chase,
  label: Effect[Effect.Rainbow_Chase],
  neededColorPickers: 0,
  initialColors: [],
};

const Fade: IEffectConfiguration = {
  effectType: Effect.Fade,
  label: Effect[Effect.Fade],
  neededColorPickers: 2,
  initialColors: [parseInt("0000ff", 16), parseInt("ff0000", 16)],
};

const Static_Color: IEffectConfiguration = {
  effectType: Effect.Static_Color,
  label: Effect[Effect.Static_Color],
  neededColorPickers: 1,
  initialColors: [parseInt("0000ff", 16)],
};

const Theater_Chase: IEffectConfiguration = {
  effectType: Effect.Theater_Chase,
  label: Effect[Effect.Theater_Chase],
  neededColorPickers: 2,
  initialColors: [parseInt("0000ff", 16)],
};

const Scanner: IEffectConfiguration = {
  effectType: Effect.Scanner,
  label: Effect[Effect.Scanner],
  neededColorPickers: 1,
  initialColors: [parseInt("0000ff", 16)],
};

const Color_Wipe: IEffectConfiguration = {
  effectType: Effect.Color_Wipe,
  label: Effect[Effect.Color_Wipe],
  neededColorPickers: 2,
  initialColors: [parseInt("0000ff", 16), parseInt("ff0000", 16)],
};

// assign the possible alarms to the table state and parts where it makes sense

export type Effects = {
  [key in TableState]: {
    selectableEffects: IEffectConfiguration[];
  };
};

const effects: Effects = {
  [TableState.Idle]: {
    selectableEffects: [
      //None,
      Rainbow,
      Fade,
      Static_Color,
      Theater_Chase,
      Scanner,
      Color_Wipe,
    ],
  },
  [TableState.In_Game]: {
    selectableEffects: [
      Rainbow,
      Fade,
      Static_Color,
      Theater_Chase,
      Scanner,
      Color_Wipe,
    ],
  },
  [TableState.Maintenance]: {
    selectableEffects: [Static_Color],
  },
  [TableState.Standby]: {
    selectableEffects: [],
  },
};

export { effects, Default_Colors };
