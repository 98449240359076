import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import Constants from "expo-constants";

// Initialize Firebase
const firebaseConfig = {
    apiKey: Constants.manifest.extra.apiKey,
    authDomain: Constants.manifest.extra.authDomain,
    databaseURL: Constants.manifest.extra.databaseUrl,
    projectId: Constants.manifest.extra.projectId,
    storageBucket: Constants.manifest.extra.storageBucket,
    messagingSenderId: Constants.manifest.extra.messagingSenderId,
    appId: Constants.manifest.extra.appId
};

let Firebase;

if (firebase.apps.length === 0) {
    Firebase = firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const db = firebase.database();
export default Firebase;

/*import * as firebase from "firebase";

import {
    API_KEY,
    AUTH_DOMAIN,
    DATABASE_URL,
    PROJECT_ID,
    STORAGE_BUCKET,
    MESSAGING_SENDER_ID,
    APP_ID,
} from "react-native-dotenv";

import "firebase/firestore";

let firebaseConfig;
// Initialize Firebase
try {
    firebaseConfig = {
        apiKey: API_KEY,
        authDomain: AUTH_DOMAIN,
        databaseURL: DATABASE_URL,
        projectId: PROJECT_ID,
        storageBucket: STORAGE_BUCKET,
        messagingSenderId: MESSAGING_SENDER_ID,
        appId: APP_ID,
    };
} catch {
    firebaseConfig = {
        apiKey: "API_KEY",
        authDomain: "AUTH_DOMAIN",
        databaseURL: "DATABASE_URL",
        projectId: "PROJECT_ID",
        storageBucket: "STORAGE_BUCKET",
        messagingSenderId: "MESSAGING_SENDER_ID",
        appId: "APP_ID",
    };
}

// if(__DEV__) {console.log(firebaseConfig) }

let toExport = "";

if ("apps" in firebase) {
    toExport = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
}
else {
    toExport = firebase.initializeApp(firebaseConfig)
}

export default toExport;*/


