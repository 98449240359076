import React from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";
import { Platform } from "react-native";
import BackgroundContainer from "../../../components/BackgroundContainer";
import { useWindowDimensions } from "react-native";
import { useTheme } from "@react-navigation/native";
import i18n from "i18n-js";
import Brightness from "../components/Brightness";
import DefaultEffect from "../components/DefaultEffect";
import DefaultColors from "../components/DefaultColors";
import AllowedEffects from "../components/AllowedEffects";
import GameDuration from "../components/GameDuration";
import WifiMode from "../components/WifiMode";
import AutoCyling from "../components/AutoCycling"
import {
  TableState
} from "../../../../types/GlobalTypes";
import commonStyles from "../../../../styles/common.style";
import { CustomTheme } from "../../../../styles/theme.style"; 7
import { db, auth } from "../../../../config/firebase";
import { TableDispatchContext, TableStateContext } from "../../../reducers/table/Context";
import { InitialTableState } from "../../../reducers/table/Reducer";

import SaveConfigurationButton from "../components/SaveConfigurationButton";
import ResetWifiModeButton from "../components/ResetWifiModeButton";
import Loading from "../../../components/Loading";
import { Default_Colors } from "../../../constants/Effects";

export default function Administration(): JSX.Element {
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const tableConfig: InitialTableState = React.useContext(TableStateContext);
  const dispatch = React.useContext(TableDispatchContext);

  //const [savedDuration, setSavedDuration] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const userId = auth.currentUser?.uid
    const idPath = tableConfig.id !== null ? tableConfig.id : "general";
    const onValueChange = db
      .ref(`/table_config/${userId}/mcu_read/${idPath}/configuration_per_state/Idle`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (__DEV__) {
          console.log('Idle config changed in db: ', data);
        }
        //rebuild empty arrays from firebase which were not stored
        if (!data.default_effect.colors) {
          data.default_effect.colors = Default_Colors
        }

        //TODO: implement user defined type guards.
        //https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
        dispatch({
          type: "SET_IDLE_CONFIGURATION",
          config: data,
        })
      });

    // Stop listening for updates when no longer required
    return () => db.ref(`/table_config/${userId}/mcu_read/${idPath}/configuration_per_state/Idle`).off('value', onValueChange);
  }, []);

  React.useEffect(() => {
    const userId = auth.currentUser?.uid
    const idPath = tableConfig.id !== null ? tableConfig.id : "general";
    const onValueChange = db
      .ref(`/table_config/${userId}/mcu_read/${idPath}/configuration_per_state/In_Game`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (__DEV__) {
          console.log('In_game config changed in db: ', data);
        }
        //rebuild empty arrays from firebase which were not stored
        if (!data.default_effect.colors) {
          data.default_effect.colors = Default_Colors
        }
        //TODO: implement user defined type guards.
        //https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
        dispatch({
          type: "SET_IN_GAME_CONFIGURATION",
          config: data,
        })
      });

    // Stop listening for updates when no longer required
    return () => db.ref(`/table_config/${userId}/mcu_read/${idPath}/configuration_per_state/In_Game`).off('value', onValueChange);
  }, []);

  if (isLoading) {
    return <Loading></Loading>;
  }

  //substract in web to center the settings
  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer?.parentNode?.removeChild(outer);

    return scrollbarWidth;
  }

  //const width = useWindowDimensions().width > 500 ? { width: "25%" } : { width: useWindowDimensions().width };
  type maxWidthType = {
    maxWidth: string | number;
  };
  let maxWidth: maxWidthType = { maxWidth: 320 };
  const currentWidth = useWindowDimensions().width;
  if (currentWidth > 560 && currentWidth < 900) {
    maxWidth = { maxWidth: 560 };
  } else if (currentWidth > 900) {
    maxWidth = { maxWidth: 420 };
  } else if (currentWidth > 1200) {
    maxWidth = { maxWidth: 560 };
  } else {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      maxWidth = { maxWidth: currentWidth - 10 };
    } else {
      maxWidth = { maxWidth: currentWidth - 10 - getScrollbarWidth() };
    }
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer} style={styles.scrollView}>
      <BackgroundContainer style={styles.backgroundContainer}>
        {/* General settings */
          <View style={[styles.tableStateContainer, maxWidth]}>
            <View style={styles.headingContainer}>
              <Text style={[commonStyles.headingTextLeft, appColors.text, styles.heading]}>
                {i18n.t("Administration.General_Settings")}
              </Text>
            </View>
            <View style={styles.container}>
              <WifiMode></WifiMode>
              <ResetWifiModeButton setIsLoading={setIsLoading}></ResetWifiModeButton>
            </View>
          </View>
        }
        {/* In game settings */}
        <View style={[styles.tableStateContainer, maxWidth]}>
          <View style={styles.headingContainer}>
            <Text style={[commonStyles.headingTextLeft, appColors.text, styles.heading]}>
              {i18n.t("TableStates." + TableState[TableState.In_Game]) +
                " " +
                i18n.t("Settings")}
            </Text>
          </View>
          <View style={styles.container}>
            <Brightness
              heading={i18n.t("Administration.Maximum_Brightness_Heading")}
              tableState={TableState.In_Game}
            ></Brightness>
            <View style={styles.effectContainer}>
              <DefaultEffect
                heading={i18n.t("Administration.Effect_Configuration_Heading")}
                tableState={TableState.In_Game}
              ></DefaultEffect>
              <AllowedEffects
                tableState={TableState.In_Game}
                heading={i18n.t("Administration.Allowed_Effects.Heading")}>
              </AllowedEffects>
              <DefaultColors heading={i18n.t("Administration.Effect_Colors_Heading")}
                tableState={TableState.In_Game}
              ></DefaultColors>
            </View>
            <View style={styles.gameDurationContainer}>
              <GameDuration heading={i18n.t("Administration.In_Game.Game_Duration.Heading")}></GameDuration>
            </View>
            <SaveConfigurationButton
              tableState={TableState.In_Game}
              setIsLoading={setIsLoading}
            //setSavedDuration={setSavedDuration}
            ></SaveConfigurationButton>
          </View>
        </View>
        {/* Indle settings */}
        <View style={[styles.tableStateContainer, maxWidth]}>
          <View style={styles.headingContainer}>
            <Text style={[commonStyles.headingTextLeft, appColors.text, styles.heading]}>
              {i18n.t("TableStates." + TableState[TableState.Idle]) + " " + i18n.t("Settings")}
            </Text>
          </View>
          <View style={styles.container}>
            <Brightness
              heading={i18n.t("Administration.Maximum_Brightness_Heading")}
              tableState={TableState.Idle}
            ></Brightness>
            <View style={styles.effectContainer}>
              <DefaultEffect
                heading={i18n.t("Administration.Effect_Configuration_Heading")}
                tableState={TableState.Idle}
              ></DefaultEffect>
              <AllowedEffects
                tableState={TableState.Idle}
                heading={i18n.t("Administration.Allowed_Effects.Heading")}>
              </AllowedEffects>
              <DefaultColors heading={i18n.t("Administration.Effect_Colors_Heading")}
                tableState={TableState.Idle}
              ></DefaultColors>
              <AutoCyling heading={i18n.t("Administration.Auto_Cycle.Heading")}></AutoCyling>
            </View>
            <SaveConfigurationButton
              tableState={TableState.Idle}
              setIsLoading={setIsLoading}
            ></SaveConfigurationButton>
          </View>
        </View>
        {/* ghost element to get a flex-start like behavior for the scnd row on wrap*/}
        <View style={[styles.tableStateContainer, maxWidth]}></View>
      </BackgroundContainer>
    </ScrollView >
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: "1%",
    marginBottom: "3%",
    alignItems: "center",
  },
  backgroundContainer: {
    flex: 1,
    paddingBottom: "1%",
    marginBottom: "3%",
    flexGrow: 1,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "stretch",
    flexDirection: "row",
  },
  tableStateContainer: {
    width: "100%",
    flexDirection: "column",
    marginHorizontal: "1%",
    marginBottom: "4%",
  },
  headingContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    alignSelf: "center",
  },
  heading: {
    marginRight: "3%",
    flexShrink: 0.4,
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: "center",
    paddingBottom: "10%",
    flexWrap: "wrap",
    alignContent: "stretch",
    flexDirection: "row",
  },
  effectContainer: {
    alignItems: "center",
    width: "100%",
  },
  gameDurationContainer: {
    alignItems: "center",
    width: "100%",
    zIndex: -1 /* for dropbox overlay */,
  },
  scrollView: {
    flexGrow: 1,
    flex: 1,
    //minWidth: "100%",
  },
});
