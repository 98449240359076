import React from "react";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useTheme } from "@react-navigation/native";
import { Pressable, StyleSheet, GestureResponderEvent } from "react-native";

import commonStyles from "../../styles/common.style";
import { CustomTheme } from "../../styles/theme.style";
import { useWindowDimensions } from "react-native";

interface IIconButton {
  onPress: (event: GestureResponderEvent) => void;
  name: string;
  style?: Record<string, unknown>;
  textStyle?: Record<string, unknown>;
  color?: string | undefined;
  size?: number | undefined;
}

export const IconButton = (props: IIconButton): JSX.Element => {
  const {
    name = "",
    style = {},
    textStyle = {},
    color = undefined,
    onPress,
    size = undefined,
  } = props;
  const { appColors }: CustomTheme = useTheme() as CustomTheme;
  const windowWith = useWindowDimensions().width;
  let calcSize: number =
    windowWith / 5 > 100 ? 50 : ((50 - 30) * (windowWith - 0)) / (450 - 0) + 30;
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        pressed
          ? {
              opacity: 0.5,
            }
          : {
              opacity: 1,
            },
      ]}
    >
      <MaterialIcons name={name} size={calcSize} color={color} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    display: "flex",

    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",

    //backgroundColor: "#2AC062",
    //shadowColor: "#2AC062",
    /*shadowOpacity: 0.4,
        shadowOffset: { height: 10, width: 0 },
        shadowRadius: 20,*/
  },

  text: {
    padding: 3,
  },
});

export default IconButton;
