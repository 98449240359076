import { ISendApiRequestResponse } from "../../../../types/GlobalTypes";
import { auth, db } from "../../../../config/firebase";
import { InitialTableState } from "reducers/table/Reducer";


const sendConfig = (tableConfig: InitialTableState, tableId: string | number | null):
    Promise<ISendApiRequestResponse> => {
    return new Promise((resolve, reject) => {
        const userId = auth.currentUser?.uid
        const idPath = tableId !== null ? tableId : "general";

        //every message needs a different writer_id, as a seconds measure
        // to avoid circular references when listening and writing to the same paths as the mcu
        //tableConfig.writer_id = Math.round(Math.random() * 1000000000000000);
        //tableConfig.state.writer_id = Math.round(Math.random() * 1000000000000000);
        if (__DEV__) {
            console.log("Config that is sent to the cloud:");
            console.log(JSON.stringify(tableConfig));
        }
        db.ref(`/table_config/${userId}/mcu_read/${idPath}`).
            update(tableConfig).then((data) => {
                if (__DEV__) console.log("Updated whole config in fb db.")
                resolve({
                    error: false,
                    code: 0,
                });
            }).catch((error) => {
                console.log('error ', error)
                reject({
                    error: true,
                    code: -1,
                    message: error.message,
                });
            })
    })
}

export { sendConfig };
