import React, { FC } from "react";
import { View, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  KeyboardTypeOptions,
} from "react-native";

interface ITextInput extends React.ComponentProps<typeof TextInput> {
  onChange?: (event: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  placeholder?: string;
  style?: Record<string, unknown>;
  textStyle?: Record<string, unknown>;
  multiline?: boolean;
  defaultValue?: string;
  keyboardType?: KeyboardTypeOptions;
  maxLength?: number;
  editable?: boolean;
  leftIcon?: string;
  leftIconColor?: string;
  rightIcon?: string;
  rightIconColor?: string;
  onRightIconClick?: () => void;
  containerStyle: any;
  placeholderTextColor?: string;
  onChangeText?: (text: string) => void;
}

const InputField: FC<ITextInput> = ({
  placeholderTextColor = "#444",
  containerStyle = {},
  leftIcon = "",
  leftIconColor = "",
  rightIcon = "",
  rightIconColor = "",
  onRightIconClick = undefined,
  style = {},
  ...rest
}): JSX.Element => {
  return (
    <View style={[styles.container, containerStyle]}>
      {leftIcon ? (
        <MaterialCommunityIcons
          name={leftIcon}
          size={20}
          color={leftIconColor}
          style={styles.leftIcon}
        />
      ) : null}
      <TextInput
        {...rest}
        placeholderTextColor={placeholderTextColor}
        style={[styles.input, style]}
      />
      {rightIcon ? (
        <TouchableOpacity onPress={onRightIconClick}>
          <MaterialCommunityIcons
            name={rightIcon}
            size={20}
            color={rightIconColor}
            style={styles.rightIcon}
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    flexDirection: "row",
    padding: 12,
  },
  leftIcon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    width: "100%",
    fontSize: 18,
  },
  rightIcon: {
    alignSelf: "center",
    marginLeft: 10,
  },
});

export default InputField;
